import React from 'react';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { releaseSeat } from '@store/seatLayout/seatLayoutActions';

import { Row, Container, Col } from 'react-bootstrap';

const GoBack = ({ t, to, force }) => {

  const reservation_id = useSelector((state) => state.booking.reservation_id);

  const dispatch = useDispatch();

  const history = useHistory();

  const onClickBack = () => {
    if(reservation_id) {
      dispatch(releaseSeat({ reservation_id: reservation_id }));
    }
    if (to === 'prev') {
      console.log(1);
      history.goBack();
      if (force === true) {
        history.goBack();
      }
    } else {
      console.log(2);
      history.push(to ? to : '/');
    }
  };

  return (
    <div className="">
      <div className="go_back py-4">
        <div className="button_wrap" onClick={() => onClickBack()}>
          <div className="go_back_button"></div>
        </div>
        <h2 className="big_text">{t('Back')}</h2>
      </div>
    </div>
  );
};

export default withNamespaces()(GoBack);
