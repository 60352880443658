import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllCategories,
  fetchFnbItems,
  fetchFnbModifiersGroup,
  fecthModifiers,
  onCloseModifierPopup,
  selectModifier,
  setFnbReservationDone,
  fetchRecipeItems,
  selectModifierUnderRecipes,
  onCloseRecipeModifierPopup,
  removeFnbItem,
} from '@store/foodAndBeverages/foodAndBeveragesActions';
import { onSelectFnb, bookFnbItems } from '@store/booking/bookingActions';
import { Container, Row, Col, Table, Alert, Modal, Spinner } from 'react-bootstrap';
// import "../assets/css/fnb.css";
import {
  timeConvert,
  isImageURLNull,
  titleCase,
  translateSessionAttribute,
} from '@helper/formatting.js';
import screen from '../assets/imgs/svgs/screen.svg';
import ip1 from '../assets/imgs/ip1.jpg';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { globalConfigContext } from '@context/GlobalConfigContext';
import i18n from '../plugins/i18n';
import { Trash } from 'react-bootstrap-icons';
import snack from '@assets/imgs/svgs/snacks_default.svg';
import movieDef from '@assets/imgs/movie-default-portrait.jpg';
import downArrow from '@assets/imgs/svgs/arrow-down.svg';
import clock from '@assets/imgs/svgs/clock.svg';
import { modifiersForSelectedFnbItems } from '@store/foodAndBeverages/foodAndBeveragesSelectors';
import TimeoutContainer from '@components/partials/TimeoutContainer';
import MovieHeader from '@components/partials/MovieHeader';
import CSpinner from '@components/partials/Spinner';
import GoBack from '@components/partials/GoBack';
import { getLangSpecificAttribute } from '../helper/Languages';
import FnbService from '@apiService/FnbService.js';
import { toast } from 'react-toastify';

const els3 = [1, 2, 3];
const els10 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const Fnb = (props) => {
  // const { arabic, currency } = useContext(globalConfigContext);
  const arabic = false;
  const currency = {};
  const { cinema_id } = props.match.params;
  const t = props.t;
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showProceedSpinner, setShowProceedSpinner] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [reserveFnbItemsError, setReserveFnbItemsError] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  // const [fnbc, setFnbc] = useState(false);
  // const modifierPopupRef = useRef();
  // const modifierPopupMobileRef = useRef();
  // const recipesPopupRef = useRef();
  // const recipesPopupMobileRef = useRef();
  const [modifierPopupValidation, setModifierPopupValidation] = useState({});
  const [recipesPopupValidation, setRecipesPopupValidation] = useState({});
  const [enableModifierConfirm, setEnableModifierConfirm] = useState(false);
  const allshowDetails = useSelector((state) => state.movies.show_times);
  const session_id = useSelector((state) => state.booking.session_id);
  // const showDetails = allshowDetails.find((show) => show.ss_id == session_id);
  // const showDetails = allshowDetails[0];
  const showDetails = useSelector((state) => state.movies.selected_session);
  const reservation_id = useSelector((state) => state.booking.reservation_id);
  const seats_string = useSelector((state) => state.booking.seats_string);
  const selectedTicketPrice = useSelector(
    (state) => state.booking.selectedTicketPrice,
  );
  const fnbCategories = useSelector(
    (state) => state.foodAndBeverages.fnb_categories,
  );
  const fnbItems = useSelector((state) => state.booking.fnb_items);

  const fnbItemsLoader = useSelector((state) => state.booking.fnb_loader);
  let kiosk_selected_lang_obj = JSON.parse(
    localStorage.getItem('kiosk_selected_language'),
  );
  let kiosk_selected_lang_id = kiosk_selected_lang_obj.lang_id;
  // console.log(fnbItems, 'fnbItems');
  let newfnbCategories = fnbItems.map((x) => ({
    ['fc_id']: x?.fc_id,
    ['fc_order']: x?.fc_order,
    ['fc_category']: x?.fc_category,
    ['alt_cat_name']: x?.FnbCategoryContent[0]?.alternate_lang_name
      ? x?.FnbCategoryContent[0]?.alternate_lang_name
      : x?.fc_category,
    ['FnbCategoryContent']: [...x?.FnbCategoryContent],
  }));

  let jsonObject = newfnbCategories.map(JSON.stringify);
  let uniqueSet = new Set(jsonObject);
  let fnbCategoriesuniqueArray = Array.from(uniqueSet).map(JSON.parse);

  // console.log(fnbCategoriesuniqueArray, 'fnbCategoriesuniqueArray');
  // let alternateLangCategoryArr = fnbCategoriesuniqueArray?.map((x) => {
  //   return x?.FnbCategoryContent;
  // });

  // let AlterNateCatLangNameArr = alternateLangCategoryArr.filter((x) => {
  //   return x?.content_lang_id == kiosk_selected_lang_id;
  // });

  // console.log(alternateLangCategoryArr, 'alternateLangCategoryArr');
  // console.log(AlterNateCatLangNameArr, 'AlterNateCatLangNameArr');

  const redirect_to_checkout = useSelector(
    (state) => state.foodAndBeverages.redirect_to_checkout,
  );
  const selectedFnbItems = useSelector((state) => state.booking.selected_fnb_items);
  const fnbPrice = useSelector((state) => state.foodAndBeverages.fnb_price);
  const selectedFnbItemId = useSelector(
    (state) => state.foodAndBeverages.selected_fnb_item_id,
  );
  const modifiersGroup = useSelector(
    (state) => state.foodAndBeverages.fnb_modifiers_group,
  );
  const modifiers = useSelector((state) => state.foodAndBeverages.fnb_modifiers);
  const showModifiersPopup = useSelector(
    (state) => state.foodAndBeverages.show_modifiers_popup,
  );
  const modifierPrice = useSelector(
    (state) => state.foodAndBeverages.modifier_price,
  );
  const currentFnbItem = useSelector(
    (state) => state.foodAndBeverages.current_fnb_item,
  );
  const seats = useSelector((state) => state.booking.seats_selected);
  const fnbReservationDone = useSelector(
    (state) => state.foodAndBeverages.fnbReservationDone,
  );
  const minutes = useSelector((state) => state.booking.timer.minutes);
  const seconds = useSelector((state) => state.booking.timer.seconds);
  const showTimeoutPopup = useSelector((state) => state.booking.showTimeoutPopup);
  const recipeItems = useSelector((state) => state.foodAndBeverages.recipe_items);
  const showRecipeItemsPopup = useSelector(
    (state) => state.foodAndBeverages.show_recipe_items_popup,
  );
  const modifiersForConcessions = useSelector((state) =>
    modifiersForSelectedFnbItems(state),
  );
  const timer = useSelector((state) => state.booking.timer);
  const seatsSelected = useSelector((state) => state.booking.seats_selected);
  const bookingFee = useSelector((state) => state.booking.bookingFee);
  const global = useSelector((state) => state.global);
  const {
    currency: { curr_code },
    selected_language: { lang_id },
    dummy_image,
  } = global;

  useEffect(() => {
    // dispatch(fetchAllCategories({ cinema_id }));
    if (fnbItems.length === 0) {
      dispatch(fetchFnbItems({ cinema_id }));
    }
    if (redirect_to_checkout) {
      onProceed();
    }
  }, [cinema_id, fnbItems.length, redirect_to_checkout]);

  // const onSelectCategory = (category) => {
  //   if (category) {
  //     setSelectedCategory(category.Name);
  //     dispatch(fetchFnbItems({ cinema_id, category_id: category.Id }));
  //   } else {
  //     setSelectedCategory('All');
  //     dispatch(fetchFnbItems({ cinema_id, category_id: null }));
  //   }
  // };

  const onSelectFnbItem = (type, item) => {
    dispatch(onSelectFnb(type, item));
    dispatch({
      type: 'SELECT_FNB_ITEM_FOR_RESERVE',
      payload: { type, item },
    });
    // if (type == "add") {
    //   dispatch(fetchFnbModifiersGroup({ item_id: fnbId }));
    //   dispatch(fecthModifiers({ item_id: fnbId }));
    // }
  };
  const handleRecipeItemPopupClose = () => {
    setShowError(false);
    setErrorMessage([]);
    let isError = false;
    let itemIndex = selectedFnbItems.findIndex(
      (item) => item.Id == selectedFnbItemId,
    );
    if (itemIndex > -1) {
      let modifiersArrLength = selectedFnbItems[itemIndex].Modifiers.length;
      for (let i = 0; i < recipeItems.length; i++) {
        let modifiersGroup = recipeItems[i].ModifierGroups;
        if (modifiersGroup.length > 0) {
          let currentModifiers =
            selectedFnbItems[itemIndex].Modifiers[modifiersArrLength - 1];
          for (let i = 0; i < modifiersGroup.length; i++) {
            let modifiersForCurrentGroup = currentModifiers.filter(
              (modifier) => modifiersGroup[i].Id === modifier.modifier_group_id,
            );
            let totalModifiersCountInCurrentGroup = modifiersForCurrentGroup.reduce(
              (acc, modifier) => {
                return acc + modifier.Quantity;
              },
              0,
            );
            if (
              totalModifiersCountInCurrentGroup > modifiersGroup[i].MaximumQuantity
            ) {
              isError = true;
              setShowError(true);
              setErrorMessage((prevState) => [
                ...prevState,
                `${t('fnb_page.please-select-only')} ${
                  modifiersGroup[i].MaximumQuantity
                } ${t('fnb_page.in')} ${titleCase(
                  modifiersGroup[i].Description,
                )} ${t('fnb_page.group')}. `,
              ]);
            } else if (
              totalModifiersCountInCurrentGroup < modifiersGroup[i].MinimumQuantity
            ) {
              isError = true;
              setShowError(true);
              setErrorMessage((prevState) => [
                ...prevState,
                `${t('fnb_page.please-select-atleast')} ${
                  modifiersGroup[i].MinimumQuantity
                } ${t('fnb_page.in')} ${titleCase(
                  modifiersGroup[i].Description,
                )} ${t('fnb_page.group')}. `,
              ]);
            }
          }
        }
      }
      if (!isError) {
        setShowError(false);
        setRecipesPopupValidation(false);
        dispatch(onCloseRecipeModifierPopup());
      } else {
        /* if(recipesPopupRef.current) {
                    recipesPopupRef.current.scroll({
                        top: 0,
                        behavior: 'smooth'
                    })
                } */
        /* if(recipesPopupMobileRef.current) {
                    recipesPopupMobileRef.current.scroll({
                        top: 0,
                        behavior: 'smooth'
                    })
                } */
      }
    }
  };
  const onSelectRecipeModifier = (recipe, modifierGroup, modifier, type) => {
    setShowError(false);
    setErrorMessage([]);

    // let currentRecipeItem = recipeItems.find(recipe => recipe.Id === recipeId);
    // let currentModifierGroup = currentRecipeItem.ModifierGroups.find(group => group.Id === modifierGroupId);
    // let currentModifier = currentModifierGroup.Modifiers.find(modifier => modifier.Id === modifierId)
    let validation = { ...recipesPopupValidation };
    let currFnbItem = selectedFnbItems.find((item) => item.Id == currentFnbItem.Id);
    let modifiersArrLength = currFnbItem.Modifiers.length;
    let totalQuantityForModifierGroup = currFnbItem.Modifiers[
      modifiersArrLength - 1
    ]
      .filter((modifier) => modifier.modifier_group_id === modifierGroup.Id)
      .reduce((acc, modifier) => acc + modifier.Quantity, 0);

    let isError = false;

    if (type === 'add') {
      if (totalQuantityForModifierGroup + 1 > modifierGroup.MaximumQuantity) {
        isError = true;
        setShowError(true);
        setErrorMessage((prevState) => [
          ...prevState,
          `${t('fnb_page.please-select-only')} ${modifierGroup.MaximumQuantity} ${t(
            'fnb_page.in',
          )} ${titleCase(modifierGroup.Description)} ${t('fnb_page.group')}. `,
        ]);
      }
      if (totalQuantityForModifierGroup + 1 >= modifierGroup.MaximumQuantity) {
        validation[modifierGroup.Description] = true;
      } else {
        validation[modifierGroup.Description] = false;
      }
    }
    if (!isError) {
      dispatch(
        selectModifierUnderRecipes({
          modifier_id: modifier.Id,
          modifier_group_id: modifierGroup.Id,
          recipe_id: recipe.Id,
          type,
        }),
      );
    } else {
      /* if(recipesPopupRef.current) {
                    recipesPopupRef.current.scroll({
                    top: 0,
                    behavior: 'smooth'
                })
            } */
      /* if(recipesPopupMobileRef.current) {
                    recipesPopupMobileRef.current.scroll({
                    top: 0,
                    behavior: 'smooth'
                })
            } */
    }
    setRecipesPopupValidation(validation);
  };

  const handleClose = () => {
    if (modifiersGroup.length > 0) {
      let itemIndex = selectedFnbItems.findIndex(
        (item) => item.Id == selectedFnbItemId,
      );
      if (itemIndex > -1) {
        let selectedModifiers = modifiersGroup.filter((x) => {
          return x.MinimumQuantity > 0;
        });
        if (selectedModifiers.length === 0) {
          dispatch(onCloseModifierPopup());
        } else if (
          selectedModifiers.length > 0 &&
          selectedFnbItems[itemIndex].Modifiers.length == 0
        ) {
          setShowError(true);
          setErrorMessage(
            'Please Select atleast ' +
              modifiersGroup[0].MinimumQuantity +
              ' modifier items',
          );
        } else {
          for (
            var i = 0;
            i <= selectedFnbItems[itemIndex].Modifiers.length - 1;
            i++
          ) {
            if (
              selectedFnbItems[itemIndex].Modifiers[i].Quantity >
              modifiersGroup[0].MaximumQuantity
            ) {
              setShowError(true);
              setErrorMessage(
                'Maximum Quantity can be ' + modifiersGroup[0].MaximumQuantity,
              );
            } else if (
              selectedFnbItems[itemIndex].Modifiers[i].Quantity >=
              modifiersGroup[0].MinimumQuantity
            ) {
              dispatch(onCloseModifierPopup());
              break;
            }
          }
        }
      }
    }
  };

  const onSelectModifier = (modifierId, type) => {
    dispatch(selectModifier(modifierId, type));
  };

  const reserve_items = useSelector((s) => s.booking.fnb_items);
  const reserve_selected = useSelector((s) => s.booking.reserve_selected_fnb_items);
  const reserve_price = useSelector((s) => s.booking.reserve_fnb_price);

  const onProceed = async () => {
    // if (selectedFnbItems.length > 0) {
    //  await dispatch(bookFnbItems({reservation_id,selectedFnbItems}))
    // }

    if (selectedFnbItems.length) {
      let payload = {
        category_id: 2,
        fnb_price: reserve_price,
        selected_fnb_items: reserve_selected,
        reservation_id,
      };

      return FnbService.SaveFnbItems(payload)
        .then((saved) => {
          console.log('saved', { ...saved?.data });
          props.history.push(`/checkout-one/${reservation_id}`);
        })
        .catch((e) => {
          toast.error(`Can not save FnB Items`);
          props.history.push(`/checkout-one/${reservation_id}`);
        });
    }

    props.history.push(`/checkout-one/${reservation_id}`);
  };
  const history = useHistory();
  const onClickBack = () => {
    history.go(-1);
  };
  return (
    <div className="">
      <CSpinner active={showSpinner} />
      {/* <TimeoutContainer t={t} session_id={session_id} cinema_id={cinema_id} /> */}
      <MovieHeader />
      <div className="bg-light-black">
        <Container fluid className="">
          <Row className="align-items-center py-3 mb-40">
            {false && (<Col>
              <GoBack to="prev" />
            </Col>)}
           {false && ( <Col className="d-flex justify-content-end">
              <span className="session-timer">
                <span>
                  {timer.minutes}:
                  {timer.seconds < 10
                    ? '0' + timer.seconds
                    : timer.seconds == 0
                    ? '00'
                    : timer.seconds}
                </span>
                <img src={clock} className={''} />
              </span>
            </Col>)}
          </Row>

          {/* <Row>
						<Col>
							<div className="d-flex align-items-center">
								<div>
									<img
										src={
											!isImageURLNull(showDetails.movie_image_url_1)
												? showDetails.movie_image_url_1
												: movieDef
										}
										alt="poster"
										height="300"
										className=""
									/>
								</div>
								<div className="d-flex flex-column kumbh px-4">
									<p className="fw-bold mb-2">
										{showDetails.movie_title}{' '}
										{showDetails.SessionDimension ? showDetails.SessionDimension : ''}{' '}
										{showDetails.movie_lang
											? showDetails.movie_lang.substring(0, 2).toUpperCase()
											: ''}
									</p>
									<p className="fw-reg mb-2">{t('Date')}</p>
									<p className="fw-bold mb-2">
										{moment(showDetails.Showtime).format('DD MMM YYYY')}
									</p>
									<p className="fw-reg mb-2">{t('Show Details')}</p>
									<p className="fw-bold mb-2">{moment(showDetails.Showtime).format('HH:mm')}</p>
								</div>
								<div className="d-flex flex-column kumbh px-4">
									<div className="group frl-12 frm-10 frs-8 fw-reg mb-2">
										<span className="border-right pr-2">{showDetails.rating}</span>
										<span className="pl-2">{timeConvert(showDetails.run_time)}</span>
									</div>
									<p className="fw-reg mb-2">{t('Seats')}</p>
									<p className="fw-bold mb-2">{seatsSelected.join(',')}</p>
									<p className="fw-reg mb-2">Voucher Code</p>
									<p className="fw-bold mb-2">ABCDS 20% off</p>
								</div>
								<div className="d-flex flex-column justify-content-end">
									<span className="blue-btn px-4 py-3"
									// onClick={handleShow}
									>PRE-BOOK</span>
								</div>
							</div>
						</Col>
					</Row> */}

          <Row>
            <Col xs={12}>
              <div className="ticket-header pb-3 zoom_7">
                <Row className="">
                  <Col xs={12}>
                    <div className="d-flex">
                      <img
                        src={
                          getLangSpecificAttribute(
                            showDetails?.MovieContent,
                            lang_id,
                            'artwork',
                          ) || dummy_image
                        }
                        className=""
                      />
                      <div className="ml-1 d-flex flex-column align-items-center w-100">
                        <Row className="mx-0 w-100 pb-3 pt-1 align-items-center">
                          <Col xs={5}>
                            <span className="fw-bold frs-28 text-limit v3 lh-1">
                              {arabic
                                ? showDetails?.movie_title_ar
                                : getLangSpecificAttribute(
                                    showDetails?.MovieContent,
                                    lang_id,
                                    'mc_title',
                                  )}
                              (
                              {showDetails?.lang_name || showDetails?.language_name}
                              )
                            </span>
                          </Col>
                          <Col xs={4}>
                            <div className="group">
                              <span
                                className={`${
                                  arabic ? 'border-left pl-2' : 'border-right pr-2'
                                }`}
                              >
                                {showDetails?.rating}
                              </span>
                              <span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
                                {!!showDetails?.mrrdr_runtime ? timeConvert(showDetails?.mrrdr_runtime) : ''}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mx-0 w-100">
                          <Col xs={5}>
                            <span>
                              <p>{t('seatlayout_page.Date')}</p>
                              <h4>
                                {moment(showDetails?.ss_start_date).format(
                                  'Do MMMM, YYYY',
                                )}
                              </h4>
                            </span>
                          </Col>
                          <Col xs={4}>
                            <span>
                              <p>{t('seatlayout_page.Seats')}</p>
                              <h6>
                                {seats_string
                                  ? seats_string
                                  : t('seatlayout_page.seats-not-selected')}
                              </h6>
                            </span>
                          </Col>
                        </Row>
                        <Row className="mx-0 w-100">
                          <Col className="col-5">
                            <span>
                              <p>{t('seatlayout_page.Show Details')}</p>
                              <h4>
                                {/* {showDetails?.SessionAttribute
                                  ? showDetails?.SessionAttribute
                                  : t("fnb_page.PREMIUM")} */}
                                {/* , */}
                                {moment(
                                  showDetails?.ss_start_show_time,
                                  'HH:mm',
                                ).format('hh:mm A')}
                              </h4>
                            </span>
                          </Col>
                          <Col className="col-5">
                          <span className="session-timer">
                            <span>
                              {timer.minutes}:
                              {timer.seconds < 10
                                ? '0' + timer.seconds
                                : timer.seconds == 0
                                ? '00'
                                : timer.seconds}
                            </span>
                            <img src={clock} className={''} />
                          </span>
                        </Col>
                        </Row>
                        {/* <div className="d-flex flex-column justify-content-between h-100 w-50">
														<div className="label-ticket">
														<h6 className="right">{showDetails?.rating}</h6>
														<h6 className="left">{timeConvert(showDetails?.run_time)}</h6>
													</div>
													</div>
													<div className="pl-4 d-flex flex-column align-items-start justify-content-between h-100 w-50">
													</div> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center bgb mb-5 py-4">
            <Col lg={12}>
              <ul
                style={{
                  overflowX:
                    fnbCategoriesuniqueArray.length > 6 ? 'scroll' : 'hidden',
                  overflowY: 'hidden',
                }}
                className="list-inline d-flex justify-content-between align-items-center blurred-white kumbh fw-reg frxl-24 frl-22 frm-18 frs-14 ls-6 feature-links"
              >
                <li
                  onClick={() => setSelectedCategory('')}
                  className={`list-inline-item text-light fw-bold cursor-pointer mb-1 ${
                    selectedCategory === '' ? 'active' : ''
                  }`}
                >
                  <a> {t('fnb_page.ALL')}</a>
                </li>
                {fnbCategoriesuniqueArray &&
                  fnbCategoriesuniqueArray.length > 0 &&
                  fnbCategoriesuniqueArray
                    ?.sort(
                      (a, b) => parseFloat(a.fc_order) - parseFloat(b.fc_order),
                    )
                    .map((fnb, fnbIndex) => (
                      <li
                        onClick={() => setSelectedCategory(fnb)}
                        key={fnbIndex}
                        className={`list-inline-item text-light fw-bold cursor-pointer ${
                          selectedCategory?.fc_id === fnb?.fc_id ? 'active' : ''
                        }`}
                      >
                        <a>
                          {kiosk_selected_lang_id == 3
                            ? fnb.alt_cat_name
                            : fnb.fc_category}
                        </a>
                      </li>
                    ))}
              </ul>
            </Col>
          </Row>

          {/* <Row className="mb-5">
            <Col className="d-flex justify-content-center bgb">
              <ul className="list-inline fnb-menu">
                <li
                  onClick={() => onSelectCategory(null)}
                  className={`list-inline-item ${
                    selectedCategory === 'All' ? 'active' : ''
                  }`}
                >
                  {t('fnb_page.ALL')}
                </li>
                {fnbCategories.map((fnb, fnbIndex) => (
                  <li
                    key={fnbIndex}
                    className={`list-inline-item ${
                      selectedCategory === fnb.Name ? 'active' : ''
                    }`}
                    onClick={() => onSelectCategory(fnb)}
                  >
                    {fnb.Name.toUpperCase()}
                  </li>
                ))}
              </ul>
            </Col>
          </Row> */}

          <Row className="sticky-bar-margin">
            <Col md={6} lg={8}>
              <Row className="fnb-section zoom_7">
                {fnbItems
                  .filter((x) => {
                    if (selectedCategory?.fc_id) {
                      return x?.fc_id == selectedCategory?.fc_id;
                    } else {
                      return x;
                    }
                  })
                  .map((item, index) => (
                    <Col lg={6} className="mb-4 col-3">
                      <div key={index} className="fnbItem">
                        <img
                          src={item.item_image_url}
                          alt={item.item_name}
                          className=""
                        />
                        <div className="w-100 d-flex pt-2 pb-1 bb-1 align-items-center">
                          <Col xs={12} className="text-limit v4">
                            <p className="itemName">
                              {kiosk_selected_lang_id == 3
                                ? item.alternate_lang_item_name
                                  ? item.alternate_lang_item_name
                                  : item.item_name
                                : item.item_name}
                            </p>
                          </Col>
                          {/* <Col xs={4} className="text-right">
														<p className="itemCal">240 kcal</p>
													</Col> */}
                        </div>
                        <div className="w-100 d-flex pb-2 pt-2 align-items-center">
                          <Col className="col-6">
                            <p className="itemPrice">
                              {curr_code} {item.fp_total_amount}
                            </p>
                          </Col>
                          <Col className="p-0 col-6">
                            <div className="w-100 h-100 d-flex justify-content-between align-items-center">
                              {/* <svg
															onClick={() => onSelectFnbItem(item.Id, 'sub', item)}
															width="1em"
															height="1em"
															viewBox="0 0 16 16"
															className="bi bi-dash-circle-fill"
															fill="#6236ff"
															xmlns="http://www.w3.org/2000/svg"
															className="fnb-icons"
														>
															<path
																fill-rule="evenodd"
																d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"
															/>
														</svg> */}

                              {/* item.Quantity && item.Quantity > 0
                                  ? "hide-minus" */}
                              <span
                                onClick={() => onSelectFnbItem('sub', item)}
                                className={`minus-icon`}
                              >
                                -
                              </span>
                              <span className="kumbh fw-bold frxl-28 frm-24 frs-20 pt-1">
                                {item.Quantity !== 0 && item.Quantity
                                  ? item.Quantity
                                  : '0'}
                              </span>
                              <span
                                onClick={() => onSelectFnbItem('add', item)}
                                className="plus-icon"
                              >
                                +
                              </span>
                              {/* <svg
															onClick={() => onSelectFnbItem(item.Id, 'add', item)}
															width="1em"
															height="1em"
															viewBox="0 0 16 16"
															className="bi bi-plus-circle-fill"
															fill="#6236ff"
															xmlns="http://www.w3.org/2000/svg"
															class="fnb-icons"
														>
															<path
																fill-rule="evenodd"
																d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
															/>
														</svg> */}
                            </div>
                          </Col>
                        </div>
                      </div>
                    </Col>
                  ))}
                {fnbItemsLoader && (
                  <div className="w-100 text-center">
                    <Spinner
                      animation="border"
                      role="status"
                      className="spinner spinner2"
                    >
                      <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                    <br />
                    <h6>{t('Loading')}</h6>
                  </div>
                )}
              </Row>
            </Col>

           {false && ( <Col md={6} lg={4}>
              <Row className="">
                <Col className="">
                  <div className="brds-10 bgb p-4 fw-bold frs-20 zoom_6 fix_height_container">
                    <p className="ls-2 frs-20 pb-3 bb-1 mb-3 text-center">
                      {t('fnb_page.food-order')}
                    </p>
                    {selectedFnbItems && selectedFnbItems.length > 0
                      ? selectedFnbItems.map((item, index) => (
                          <div>
                            <div className="d-flex justify-content-between">
                              <div
                                key={index}
                                className="d-flex justify-content-between align-items-center pb-3 mr-2 flex-grow-1"
                              >
                                <span className="pr-2 fw-reg">
                                  {item.item_name} (x{item.Quantity})
                                  {false && (
                                    <img
                                      src={downArrow}
                                      alt={index}
                                      className="cursor-pointer d-inline-block pl-3"
                                    />
                                  )}
                                </span>
                                <span className="frs-24">
                                  {curr_code}{' '}
                                  {(item.priceInCents * item.Quantity).toFixed(3)}
                                </span>
                              </div>
                              {/* <Trash className="cursor-pointer" size={24} /> */}
                            </div>
                            {false && (
                              <div className="cdropdown hide">
                                {[].map((index) => (
                                  <div
                                    key={index}
                                    className="d-flex justify-content-between align-items-center pb-3"
                                  >
                                    <span className="fw-reg">
                                      {item.item_name} (x{item.Quantity})
                                    </span>
                                    <span className="frs-24">
                                      {curr_code}{' '}
                                      {(item.priceInCents * item.Quantity).toFixed(
                                        2,
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))
                      : null}
                    <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                      {/* <span className="fw-reg">
                        {t("fnb_page.Taxes")}
                        <br /> */}
                      {/* <span className="blurred-white frs-14 fw-reg">
                          (15%)
                        </span> */}
                      {/* </span> */}
                      <span className="frs-24"></span>
                    </div>
                    <div className="d-flex justify-content-between">
                      {/* <span className="fw-reg">{t("fnb_page.Total")}</span> */}
                      <span className="frs-24">
                        {/* {(
                          (parseFloat(fnbPrice.priceInCents) +
                            modifierPrice.priceInCents) /
                          currency.divided_by
                        ).toFixed(3)}{" "} */}
                        {arabic
                          ? currency.currency_short_code_ar
                          : currency.currency_short_code}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>)}
          </Row>

          {/* <Row className="align-items-center bgb mt-5 py-3">
						<Col md={8} className="br-1">
							<div className="d-flex">
								<div className="kumbh d-flex flex-column px-4">
									<p className="fw-reg">{t('Total Payable')}</p>
									<p className="fw-bold">{selectedTicketPrice.priceInCents / 100} SR</p>
									<p className="fw-reg">
										{t('Total')}:{' '}
										{(parseFloat(selectedTicketPrice.priceInCents) +
											parseFloat(fnbPrice.priceInCents) +
											modifierPrice.priceInCents) /
											100}{' '}
										SR
									</p>
								</div>
								<div className="d-flex flex-column justify-content-center">
									<p className="pill bg-light-black-2 p-3 fw-bold">{t('Total Bill')}</p>
								</div>
							</div>
						</Col>
						<Col md={4}>
							<span onClick={handleProceed} className="blue-btn px-4 py-3">
								{t('PROCEED')}
							</span>
						</Col>
					</Row> */}

          <Row className="align-items-center bgb mt-5 sticky-movie-bar">
          <Col className='col-12'>
          <Row className="">
            <Col className="">
              <div className="brds-10 bgb p-4 fw-bold frs-20 zoom_6 fix_height_container">
                <p className="ls-2 frs-20 pb-3 bb-1 mb-3 text-center">
                  {t('fnb_page.food-order')}
                </p>
                {selectedFnbItems && selectedFnbItems.length > 0
                  ? selectedFnbItems.map((item, index) => (
                      <div>
                        <div className="d-flex justify-content-between">
                          <div
                            key={index}
                            className="d-flex justify-content-between align-items-center pb-3 mr-2 flex-grow-1"
                          >
                            <span className="pr-2 fw-reg">
                              {item.item_name} (x{item.Quantity})
                              {false && (
                                <img
                                  src={downArrow}
                                  alt={index}
                                  className="cursor-pointer d-inline-block pl-3"
                                />
                              )}
                            </span>
                            <span className="frs-24">
                              {curr_code}{' '}
                              {(item.priceInCents * item.Quantity).toFixed(3)}
                            </span>
                          </div>
                          {/* <Trash className="cursor-pointer" size={24} /> */}
                        </div>
                        {false && (
                          <div className="cdropdown hide">
                            {[].map((index) => (
                              <div
                                key={index}
                                className="d-flex justify-content-between align-items-center pb-3"
                              >
                                <span className="fw-reg">
                                  {item.item_name} (x{item.Quantity})
                                </span>
                                <span className="frs-24">
                                  {curr_code}{' '}
                                  {(item.priceInCents * item.Quantity).toFixed(
                                    2,
                                  )}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))
                  : null}
                <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                  {/* <span className="fw-reg">
                    {t("fnb_page.Taxes")}
                    <br /> */}
                  {/* <span className="blurred-white frs-14 fw-reg">
                      (15%)
                    </span> */}
                  {/* </span> */}
                  <span className="frs-24"></span>
                </div>
                <div className="d-flex justify-content-between">
                  {/* <span className="fw-reg">{t("fnb_page.Total")}</span> */}
                  <span className="frs-24">
                    {/* {(
                      (parseFloat(fnbPrice.priceInCents) +
                        modifierPrice.priceInCents) /
                      currency.divided_by
                    ).toFixed(3)}{" "} */}
                    {arabic
                      ? currency.currency_short_code_ar
                      : currency.currency_short_code}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          </Col>
            <Col xs={12} className="seat-footer">
              <Row className="">
                <Col
                  className="d-flex justify-content-between align-items-center col-12"
                >
                <GoBack to="prev" />
                <div className="zoom_7">
                    <div>
                      <h6 className="f-grey">{t('fnb_page.Total Payable')}</h6>
                      <h5 className="fw-bold">
                        {curr_code}{' '}
                        {(
                          parseFloat(selectedTicketPrice.priceInCents) +
                          parseFloat(fnbPrice.priceInCents) +
                          modifierPrice.priceInCents
                        ).toFixed(3)}{' '}
                        {arabic
                          ? currency.currency_short_code_ar
                          : currency.currency_short_code}
                      </h5>
                    </div>
                    {/* <div className={`flex-center ${arabic ? "pr-5" : "pl-5"}`}>
                      <h6 className="custom-label">
                        {t("fnb_page.Total Bill")}
                      </h6>
                    </div> */}
                  </div>
                  <span className="blue-btn btn-lg px-5" onClick={onProceed}>
                    {showProceedSpinner ? (
                      <Spinner animation="border" role="status" className="spinner">
                        <span className="sr-only">{t('Loading')}</span>
                      </Spinner>
                    ) : (
                      t('common.PROCEED')
                    )}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Modal
          // show={show}
          // onHide={handleClose}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          className="1"
        >
          <div className="bg-light-black-2 brds-8">
            <div className="my-4 kumbh d-flex flex-column align-items-center">
              <p className="mb-2">{t('fnb_page.Enter your contact details')}</p>
              <input
                type="email"
                placeholder={t('collect_login.placeholders.email')}
                className="p-3 rounded-lg mb-2"
              />
              <input
                type="number"
                placeholder={t('collect_login.placeholders.mobile')}
                className="p-3 rounded-lg"
              />
            </div>
          </div>
        </Modal>

        <Modal
          show={showTimeoutPopup}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-time-up"
          backdrop="static"
        >
          <Modal.Header closeButton={false}>
            <p className="kumbh fw-bold frxl-36 frl-32 frm-28 frs-24 ls-4">
              {t('timeout.time-up')}
            </p>
          </Modal.Header>
          <Modal.Body>
            <p className="kumbh fw-reg frxl-24 frl-20 frs-16">
              {t('timeout.session-expired')}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <span
              onClick={() => history.push('/')}
              className="blue-btn mxh-47 px-3 frxl-18 frl-16 frs-14"
            >
              {t('timeout.go-back')}
            </span>
          </Modal.Footer>
        </Modal>

        {/* Modifier Popups */}
        {/* <Modal
          show={showModifiersPopup}
          onHide={handleClose}
          centered
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-5"
        >
          <div className="bg-light-black brds-8">
            <div className="w-100 mxh-161 img-fit-2 brds-10">
              <img
                src={
                  !isImageURLNull(currentFnbItem.concession_image_url_1)
                    ? currentFnbItem.concession_image_url_1
                    : snack
                }
                alt={currentFnbItem.Description}
                className="brds-10"
              />
            </div>
            <div className="px-3 py-2 d-flex justify-content-between align-items-center">
              <p className="fw-bold frs-20">
                {titleCase(currentFnbItem.Description)}
              </p>
              <span className="modifier-btn frs-22 fw-bold mb-3">
                <span
                  className="mod-add"
                  onClick={() =>
                    onSelectFnbItem(currentFnbItem.Id, "sub", currentFnbItem)
                  }
                >
                  -
                </span>
                <span className="">{currentFnbItem.Quantity}</span>
                <span
                  className={`mod-remove ${
                    !enableModifierConfirm ? "inactive" : ""
                  }`}
                  onClick={() =>
                    enableModifierConfirm
                      ? onSelectFnbItem(
                          currentFnbItem.Id,
                          "add",
                          currentFnbItem
                        )
                      : null
                  }
                >
                  +
                </span>
              </span>
            </div>
            {showError ? (
              <span className="frs-12 form-error-text d-block text-align-center px-3 mb-3 frs-12">
                {errorMessage.join(" ")}
              </span>
            ) : null}
            <div className="px-3 w-100 mb-40 items-div">
              {modifiersGroup
                .filter(
                  (singleGroup) =>
                    modifiers.filter(
                      (modifier) =>
                        modifier.modifier_group_id === singleGroup.Id
                    ).length > 0
                )
                .map((singleGroup, singleGroupIndex) => (
                  <>
                    <div key={singleGroupIndex} className="pt-2">
                      <p className="kumbh fw-bold frs-16">
                        {titleCase(singleGroup.Description)}
                        {singleGroup.MaximumQuantity !== 0 ||
                        singleGroup.MinimumQuantity !== 0
                          ? ` (${t("fnb_page.you-can-only-select")} ${
                              singleGroup.MaximumQuantity !== 0
                                ? `${t("fnb_page.max-of")} ${
                                    singleGroup.MaximumQuantity
                                  }`
                                : ""
                            } ${
                              singleGroup.MinimumQuantity !== 0
                                ? ` ${t("fnb_page.min-of")} ${
                                    singleGroup.MinimumQuantity
                                  }`
                                : ""
                            })`
                          : ""}
                      </p>
                    </div>

                    {modifiers
                      .filter(
                        (modifier) =>
                          modifier.modifier_group_id === singleGroup.Id
                      )
                      .map((modifier, modifierIndex) => (
                        <div
                          index={modifierIndex}
                          className="pb-20 pt-20 bb-1 d-flex justify-content-between align-items-center"
                        >
                          <p className="fw-reg frs-16">
                            {titleCase(modifier.Description)}
                          </p>
                          <span
                            className={`modifier-btn ${
                              modifierPopupValidation[
                                singleGroup.Description
                              ] && modifier.Quantity === 0
                                ? "inactive"
                                : ""
                            }`}
                          >
                            <span
                              onClick={() =>
                                onSelectModifier(modifier.Id, "sub")
                              }
                              className={`frs-16 fw-bold mod-remove ${
                                modifier.Quantity == 0 ? "hide-minus" : ""
                              }`}
                            >
                              -
                            </span>
                            <span className="frxl-22 frl-20 frs-18 fw-bold">
                              {modifier.Quantity}
                            </span>
                            <span
                              onClick={() =>
                                onSelectModifier(modifier.Id, "add")
                              }
                              className={`frs-16 fw-bold mod-add ${
                                modifierPopupValidation[
                                  singleGroup.Description
                                ] === true
                                  ? "inactive"
                                  : ""
                              }`}
                            >
                              +
                            </span>
                          </span>
                        </div>
                      ))}
                  </>
                ))}
            </div>
            <div className="mb-40 w-100 d-flex py-4">
              <span
                className={`mx-auto blue-btn px-4 frs-16`}
                onClick={() =>
                  onSelectFnbItem(
                    currentFnbItem.Id,
                    "sub",
                    currentFnbItem,
                    true
                  )
                }
              >
                {t("common.CANCEL")}
              </span>
              <span
                onClick={handleClose}
                className={`mx-auto blue-btn px-4 frs-16 ${
                  !enableModifierConfirm ? "inactive-btn" : ""
                }`}
              >
                {t("fnb_page.add-to-cart")}
              </span>
            </div>
          </div>
        </Modal>

        <Modal
          show={showRecipeItemsPopup}
          onHide={handleRecipeItemPopupClose}
          centered
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-5"
        >
          <div className="bg-light-black brds-8">
            <div className="w-100 mxh-161 img-fit-2 brds-10">
              <img
                src={
                  !isImageURLNull(currentFnbItem.concession_image_url_1)
                    ? currentFnbItem.concession_image_url_1
                    : snack
                }
                alt={titleCase(currentFnbItem.Description)}
                className="brds-10"
              />
            </div>
            <div className="px-3 py-2 d-flex justify-content-between align-items-center">
              <p className="fw-bold frs-20">
                {titleCase(currentFnbItem.Description)}
              </p>
              <span className="modifier-btn frs-22 fw-bold">
                <span
                  className="mod-add"
                  onClick={() =>
                    onSelectFnbItem(currentFnbItem.Id, "sub", currentFnbItem)
                  }
                >
                  -
                </span>
                <span className="">{currentFnbItem.Quantity}</span>
                <span
                  className={`mod-remove ${
                    !enableModifierConfirm ? "inactive" : ""
                  }`}
                  onClick={() =>
                    enableModifierConfirm
                      ? onSelectFnbItem(
                          currentFnbItem.Id,
                          "add",
                          currentFnbItem
                        )
                      : null
                  }
                >
                  +
                </span>
              </span>
            </div>
            {/* <div class="pb-20 bb-1 w-100">
                            <p className="w-75 kumbh fw-reg frxl-14 frs-12 blurred-white">A full description of the pizza goes here over 1-2 lines so the user can fully understand the type of pizza and ingredients going into it along with nutritional value</p>
                        </div> */}
        {/* {showError ? (
              <span className="form-error-text d-block text-align-center px-3 mb-2 frs-12">
                {errorMessage.join(" ")}
              </span>
            ) : null}
            <div className="px-3 w-100 mb-40 items-div">
              {recipeItems.map((recipeItem, recipeItemIndex) => (
                <>
                  <p className="kumbh fw-bold frs-16">
                    {titleCase(recipeItem.Description)}
                  </p>

                  {recipeItem.ModifierGroups.map(
                    (singleGroup, singleGroupIndex) => (
                      <>
                        <div key={singleGroupIndex} className="pt-2">
                          <p className="kumbh fw-bold frs-16">
                            {titleCase(singleGroup.Description)}
                            {singleGroup.MaximumQuantity !== 0 ||
                            singleGroup.MinimumQuantity !== 0
                              ? ` (${t("fnb_page.you-can-only-select")} ${
                                  singleGroup.MaximumQuantity !== 0
                                    ? `${t("fnb_page.max-of")} ${
                                        singleGroup.MaximumQuantity
                                      }`
                                    : ""
                                } ${
                                  singleGroup.MinimumQuantity !== 0
                                    ? ` ${t("fnb_page.min-of")} ${
                                        singleGroup.MinimumQuantity
                                      }`
                                    : ""
                                })`
                              : ""}
                          </p>
                        </div>

                        {singleGroup.Modifiers.map(
                          (modifier, modifierIndex) => (
                            <div
                              index={modifierIndex}
                              className="pb-20 pt-20 bb-1 d-flex justify-content-between align-items-center"
                            >
                              <p className="fw-reg frs-16">
                                {titleCase(modifier.Description)}
                              </p>
                              <span
                                className={`modifier-btn ${
                                  recipesPopupValidation[
                                    singleGroup.Description
                                  ] && modifier.Quantity === 0
                                    ? "inactive"
                                    : ""
                                }`}
                              >
                                <span
                                  onClick={() =>
                                    onSelectRecipeModifier(
                                      recipeItem,
                                      singleGroup,
                                      modifier,
                                      "sub"
                                    )
                                  }
                                  className={`frs-16 fw-bold mod-remove ${
                                    modifier.Quantity == 0 ? "hide-minus" : ""
                                  }`}
                                >
                                  -
                                </span>
                                <span className="frs-22 fw-bold">
                                  {modifier.Quantity}
                                </span>
                                <span
                                  onClick={() =>
                                    onSelectRecipeModifier(
                                      recipeItem,
                                      singleGroup,
                                      modifier,
                                      "add"
                                    )
                                  }
                                  className={`frs-16 fw-bold mod-add ${
                                    recipesPopupValidation[
                                      singleGroup.Description
                                    ]
                                      ? "inactive"
                                      : ""
                                  }`}
                                >
                                  +
                                </span>
                              </span>
                            </div>
                          )
                        )}
                      </>
                    )
                  )}
                </>
              ))}
            </div>
            <div className="mb-40 w-100 d-flex py-4">
              <span
                className={`mx-auto blue-btn frs-16 px-4`}
                onClick={() =>
                  onSelectFnbItem(
                    currentFnbItem.Id,
                    "sub",
                    currentFnbItem,
                    true
                  )
                }
              >
                {t("common.CANCEL")}
              </span>
              <span
                onClick={handleRecipeItemPopupClose}
                className={`mx-auto blue-btn frs-16 px-4 ${
                  !enableModifierConfirm ? "inactive-btn" : ""
                }`}
              >
                {t("fnb_page.add-to-cart")}
              </span>
            </div>
          </div>
        </Modal>  */}
      </div>
    </div>
  );
};
// const MovieHeader = () => {
// 	const changeLanguage = (lng) => {
// 		let stylesSheets = document.styleSheets;
// 		let lastStyleSheet = document.styleSheets[stylesSheets.length - 1];
// 		if (lng === "ar") {
// 			require("../assets/css/arabic.css");
// 			lastStyleSheet.disabled = false;
// 			document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
// 		} else {
// 			lastStyleSheet.disabled = true;
// 			document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
// 		}
// 		i18n.changeLanguage(lng);
// 	};
// 	return (
// 		<div className="movie_header">
// 			<div className="logo"></div>
// 			<h2 className="big_text">OASIS MALL</h2>
// 			<div className="dropdown_div">
// 				<select onChange={(e) => changeLanguage(e.target.value)} className="dropdown" name="language" id="language">
// 					<option value="en">ENGLISH</option>
// 					<option value="ar">ARABIC</option>
// 				</select>
// 			</div>
// 		</div>
// 	);
// };

export default withNamespaces()(Fnb);
