import React, {
  Component,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  FunctionComponent,
  ChangeEvent,
} from 'react';
import deleteIcon from '../assets/imgs/svgs/delete-red-light.svg';
import { BASEURL, TOKEN } from '@config';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FnbService from '@apiService/FnbService.js';
import masterCardStandardCharteredLogo from '@assets/imgs/scLogo.png';
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Modal,
  Spinner,
  Dropdown,
  Accordion,
} from 'react-bootstrap';
// import { Accordion } from 'react-bootstrap/Accordion';
import screen from '../assets/imgs/svgs/screen.svg';
import ip1 from '../assets/imgs/ip1.jpg';
import moment from 'moment';
import { PaymentService } from '@apiService/PaymentService';
import BookingService from '@apiService/BookingService';
import { withNamespaces } from 'react-i18next';
import i18n from '../plugins/i18n';
import { SOCKET_URL } from '@config';
import clock from '@assets/imgs/svgs/clock.svg';
import * as yup from 'yup';
import KeyboardWrapper from './KeyboardWrapper';
import { toast } from 'react-toastify';
import {
  bookSeats,
  bookFnb,
  fetchPaymentModes,
  fetchReservationDetailsBrij,
  verifyVoucherCode,
  makeCCAvenuePayment,
  makeKhaltiPayment,
  makeEsevaPayment,
  makeIMEPayment,
  makeAlrajhiPayment,
  makeBenefitCardPayment,
  makeMasterCardScPayment,
  makeMasterCardAsfPayment,
  makeMasterCardApplePay,
  validateBankOfferAction,
  clearCardInfo
} from '@store/booking/bookingActions';
import MovieHeader from '@components/partials/MovieHeader';
import GoBack from '@components/partials/GoBack';
import { formatYuppErrors } from '@helper/formatting';
import { ConeStriped } from 'react-bootstrap-icons';
import { SET_CUST_MOB_CODE } from '../store/global/globalTypes';
import { getLangSpecificAttribute } from '../helper/Languages';
import QRCode from 'qrcode.react';
import { useInterval } from '../customHooks/useInterval';
import { SET_BOOKING_CODE, SET_BOOKING_ID } from '../store/booking/bookingTypes';
import { setBookingDetails } from '../store/booking/bookingActions';
import { releaseSeat } from '@store/seatLayout/seatLayoutActions';

// import { globalConfigContext } from '@context/GlobalConfigContext';
var wsUrl = SOCKET_URL;
var socket = new WebSocket(wsUrl);

const keyboardOptions = {
  layout: {
    default: ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 {enter}'],
    shift: ['! / #', '$ % ^', '& * (', '{shift} ) +', '{bksp}'],
  },
  theme: 'hg-theme-default hg-layout-numeric numeric-theme',
};

const CheckoutOne = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const [showPaymentModesModal, setShowPaymentModesModal] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [fnbOfferData, setFnbOfferData] = useState([]);
  const [offerPrice, setOfferPrice] = useState(0);
  const [finalAmountToPay, setFinalAmount] = useState(0);
  const [filteredPaymentModes, setfilteredPaymentModes] = useState([]);

  const timer = useSelector((state) => state.booking.timer);
  const { reservation_id } = props.match.params;
  const allshowDetails = useSelector((state) => state.movies.show_times);
  const showDetails = useSelector((state) => state.movies.selected_session);

  const [showKeyboard, setShowKeyboard] = useState(false);
  const [showKeyboardVoucher, setShowKeyboardVoucher] = useState(false);
  const [showKeyboardMobile, setShowKeyboardMobile] = useState(false);
  const [showSearchKeyboard, setshowSearchKeyboard] = useState(false);

  const keyboard = useRef(null);
  const keyboardMobile = useRef(null);
  const keyboardSearch = useRef(null);
  const keyboardVoucher = useRef(null);

  const [voucherCode, setVoucherCode] = useState(``);
  const [redirectData, setredirectData] = useState(null);
  const [selectedPaymentMode, setSetSelectedPaymentMode] = useState(null);

  const [checkBoookingCount, setCheckBookingCount] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const selectedFnbItems = useSelector((state) => state.booking.selected_fnb_items);
  const seatSelected = useSelector((state) => state.booking.seats_selected);
  const showTimeoutPopup = useSelector((state) => state.booking.showTimeoutPopup);
  const paymentModes = useSelector((state) => state.booking.payment_modes);
  const totalTicketPrices = useSelector((state) => state.booking.totalAmount);
  const netTicketPrices = useSelector((state) => state.booking.netAmount);
  const t = props.t;
  const seats_string = useSelector((state) => state.booking.seats_string);
  const global = useSelector((state) => state.global);
  // console.log(seatSelected, 'seatSelected');
  const {
    loading,
    currency: { curr_code, curr_id },
    cinema: { cinema_id },
    countries,
    cust_mob_code,
    dummy_image,
    mode,
    selected_language: { lang_id },
  } = global;
  let isEmpireClient = global?.globalSettings?.org_name?.includes('Empire');
  let allowKeyBoard = global?.globalSettings?.org_name?.includes('epix') ? false : true;
  let web_origin = global?.globalOptions?.find((g) => g.go_key === 'WEBSITE_URL');
  let fnb_origin = global?.globalOptions?.find((g) => g.go_key === 'FNB_APP_URL');

  const selectedTicketPrice = useSelector(
    (state) => state.booking.selectedTicketPrice,
  );

  const voucherDiscount = useSelector((state) => state.booking.voucherAmount);
  const vouchers = useSelector((state) => state.booking.vouchers);
  const reservationDetails = useSelector((state) => state.booking.reservationDetails);
  const successfulVoucherCode = useSelector(
    (state) => state.booking.successfulVoucherCode,
  );
  let countriesCopy = useRef(countries);

  const cardInfo = useSelector((state) => state.booking.cardInfo);
  const {
    bank_card_number,
    bank_card_number_with_space,
    bank_validate_msg,
    is_valid,
    bank = null,
  } = cardInfo;

  const [validEmail, setValidEmail] = useState('');
  const [validMobile, setValidMobile] = useState('');
  // const [selectedCountry, setSelectedCountry] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loader, setloader] = useState(false);
  const [selectedBtn, setselectedBtn] = useState('');
  const [fnbPrice, setfnbPrice] = useState({});
  const [ConvFees, setConvFees] = useState(0);
  const schema = yup.object().shape({
    // validEmail: yup.string().email().required(),
    validEmail: yup.string().email(),
    validMobile: yup.string().min(7).max(10).required(),
  });
  const [searchCountryCode, setSearchCountryCode] = useState('');

  const [bankOfferLoading, setBankLoading] = useState(false);
  const [cardInfoTemp, setCardInfoTemp] = useState({
    bank_card_number: '',
    bank_card_number_with_space: '',
    bank_validate_msg: '',
    is_loading: false,
    is_valid: false,
    bank: null,
    offerDetails: null
  });

  useEffect(() => {
    let tempPmModes = [];
    console.log('bank', bank);
    console.log('offerPrice', offerPrice);
    console.log('cardInfo', cardInfo);
    tempPmModes = paymentModes.filter((_pm) =>
      bank && offerPrice?.isOfferApplied && bank?.toLowerCase()?.includes('scb')
        ? _pm?.pm_id == 44
        : _pm?.bank == null,
    );
    setfilteredPaymentModes(tempPmModes)
  }, [paymentModes, bank, offerPrice])

  useEffect(() => {
    if(is_valid && cardInfoTemp?.offerDetails) {
      VerifyFnbOfferData(cardInfoTemp?.offerDetails)
    }
    setCardInfoTemp({
      ...cardInfoTemp,
      bank_validate_msg: bank_validate_msg,
      is_valid: is_valid,
      bank: bank
    })

  }, [is_valid])

  const onRemoveOffer = async () => {
    setOfferPrice(0);
    setCardInfoTemp({
      bank_card_number: '',
      bank_card_number_with_space: '',
      bank_validate_msg: '',
      is_loading: false,
      is_valid: false,
      bank: null,
      offerDetails: null
    })
    await dispatch(clearCardInfo)
    await FnbService.ResetVoucherAndOfferInReservation({
      reservation_id: reservation_id,
    })
  }

  const VerifyFnbOfferData = async (data) => {
    // setOfferPrice((offerPrice) => ({
    //   ...offerPrice,
    //   ['oc_discount_value']: data?.oc_discount_value,
    //   ['oc_id']: data?.oc_id,
    // }));
    let VerifypayloadNew = {};
    if (mode == 2) {
      VerifypayloadNew = {
        cinema_id: cinema_id.toString(),
        cinema_name: '',
        customer_id: '',
        fnb_items: selectedFnbItems,
        isTicket: false,
        net_amount: fnbPrice?.totalWithFnb - fnbPrice?.taxWithFnb,
        oc_id: data?.oc_id,
        total_amount: fnbPrice?.totalWithFnb
          ? fnbPrice?.totalWithFnb
          : fnbPrice?.priceInCents,
        total_ticket: selectedFnbItems.length,
        fnb_reservation_id: reservation_id,
      };
    } else if (mode == 3 || mode == 1) {
      VerifypayloadNew = {
        cinema_id: cinema_id.toString(),
        cinema_name: '',
        customer_id: '',
        fnb_items: selectedFnbItems,
        isTicket: true,
        net_amount: netTicketPrices,
        oc_id: data?.oc_id,
        md_id: showDetails?.movie_details_id,
        reservation_id: reservation_id,
        total_amount: totalTicketPrices ? totalTicketPrices : 0,
        total_ticket: seatSelected.length,
        fnb_net_amount: selectedTicketPrice?.totalWithFnb
          ? selectedTicketPrice?.totalWithFnb - selectedTicketPrice?.taxWithFnb
          : 0,

        fnb_total_amount: selectedTicketPrice?.totalWithFnb
          ? selectedTicketPrice?.totalWithFnb
          : 0,
        fnb_total_item: selectedFnbItems.length,
        isComboApplied: true,
      };
    }

    if (mode == 3 || mode == 2) {
      await FnbService.ResetVoucherAndOfferInReservation({
        reservation_id: reservation_id,
      })
        .then((response) => {
          // console.log('offerdata', { ...response?.data });
          let result = response?.data;

          console.log(result, 'resultsave');
        })
        .catch((e) => {
          toast.error(`Offer Not Saved`);
          // props.history.push(`/`);
        });
    }

    await FnbService.VerifyFnbOffers(VerifypayloadNew)
      .then((response) => {
        if (response?.data?.status) {
          let result = response?.data;

          let OfferValueMinusTotalFnbAmount = result?.BoxOffice?.actualTotalGross;
          let VerifyOfferRecords = result?.Records;
          let offer_title = VerifyOfferRecords[0]?.offer_title;
          setOfferPrice((offerPrice) => ({
            ...offerPrice,
            ['actualTotalGross']: OfferValueMinusTotalFnbAmount,
            ['TotalOfferPrice']: result?.BoxOffice?.TotalOfferPrice,
            ['isOfferApplied']: true,
            ['offer_title']: offer_title,
          }));
          console.log('offerPrice', offerPrice);
          setShowOfferModal(false);
          // console.log(OfferValueMinusTotalFnbAmount, 'OfferValueMinusTotalFnbAmount');
          // console.log(result, 'VerifyOfferData');

          // props.history.push(`/checkout-one/${reservation_id}`);
        } else {
          toast.error(`${response?.data?.message}`);
        }
        // console.log('offerdata', { ...response?.data });
      })
      .catch((e) => {
        toast.error(`Offer Not Verified`);
        // props.history.push(`/`);
      });

    if (mode == 3 || mode == 1) {
      let savevoucherPayoad = {
        oc_id: data?.oc_id,
        reservation_id: reservation_id,
        offer_promotion_payload: JSON.stringify(VerifypayloadNew),
      };
      await FnbService.SavePromotionInReservation(savevoucherPayoad)
        .then((response) => {
          // console.log('offerdata', { ...response?.data });
          let result = response?.data;

          console.log(result, 'resultsave');
        })
        .catch((e) => {
          toast.error(`Offer Not Saved`);
          // props.history.push(`/`);
        });
    }
  };

  const onSearchCountry = (e) => {
    console.log('e', e);
    setSearchCountryCode(e);
    searchCountryFunction();
    console.log('cust_mob_code', cust_mob_code);
  };

  const searchCountryFunction = useCallback(() => {
    countriesCopy.current = countries.filter((country) => {
      if (
        country.country_mob_code &&
        country.country_mob_code
          .toLowerCase()
          .includes(searchCountryCode.toLowerCase())
      ) {
        return country;
      }
    });
  }, [searchCountryCode]);

  const onProceed = () => {
    localStorage.removeItem('selectedPaymentMode');
    localStorage.removeItem('fop_id');
    localStorage.removeItem('guest_detail');
    localStorage.removeItem('guest_id');
    localStorage.removeItem('mapped_cust_id');

    const el = document.getElementById('mobileCheckout');
    console.log(el, "elllllll");
    if (el) {
      el.scrollIntoView();
    }

    let guest_user_id = localStorage.getItem('guest_user_id');
    setSubmitted(true);
    schema
      .validate(
        {
          validEmail,
          validMobile,
        },
        { abortEarly: false },
      )
      .then((valid) => {
        console.log('in valid ');
        // dispatch(setUserDetails(email, `${countryCode}${mobile}`));
        // props.history.push('/process-payment');
        if (valid) {
          let guest_detail = {
            guest_email:  validEmail || 'guest.kiosk@brij.tech',
            guest_first_name: 'Guest',
            guest_last_name: 'User',
            guest_mobile: validMobile,
            guest_country_code:
              `${cust_mob_code}`.replace(/\+/gim, '').trim() || '973',
            guest_user_id: guest_user_id,
            isUpdateLang: false,
            selected_lang_id: 1,
            country_id: 17,
          };
          localStorage.setItem('guest_detail', JSON.stringify(guest_detail));

          return BookingService.GuestRegister(guest_detail)
            .then((res) => {
              setShowPaymentModesModal(true);
              return res;
            })
            .catch((e) => Promise.resolve({ status: false }));
        } 

        return Promise.resolve({ status: false });
      })
      .then((resp) => {
        if (resp?.data?.status && resp?.data.data?.[0]?.guest_id) {
          let guest_id = resp?.data.data?.[0]?.guest_id;
          localStorage.setItem('guest_id', guest_id);

          let mapped_cust_id = resp?.data.data?.[0]?.mapped_cust_id || '';
          if (mapped_cust_id) {
            localStorage.setItem('mapped_cust_id', mapped_cust_id);
          }
        }
      })
      .then(() => {
        return;
      })
      .catch((errs) => {
        let errors = formatYuppErrors(errs);
        setErrors(errors);
      });
  };
  
  const onCancel = () => {
    if(reservation_id) {
      dispatch(releaseSeat({ reservation_id: reservation_id }));
    }
    props.history.replace(`/`);
  }

  const paymentModeHandler = (paymentMode, pm_id) => {
    setloader(true);
    setselectedBtn(pm_id);
    makePayment(paymentMode);
  };

  const paymentModeFnbHandler = (paymentMode, pm_id) => {
    setloader(true);
    setselectedBtn(pm_id);
    localStorage.setItem('selectedPaymentMode', paymentMode?.pm_payment_mode);
    // console.log(paymentMode, 'paymentModepaymentMode');
    makeonlyFnBPayment(paymentMode);
  };

  const validateVoucher = () => {
    if (voucherCode) {
      dispatch(
        verifyVoucherCode({
          voucherCode,
          vouchers,
          selectedTicketPrice,
          callback2: setloader,
        }),
      );
    } else {
      toast.error(`Please enter a voucher code`);
    }
  };

  const onChangeInput = (event) => {
    const input = event.target.value;
    setValidEmail(input);
    if(allowKeyBoard) {
      keyboard.current.setInput(input);
    }
    setErrors({ ...errors, validEmail: null });
  };

  const onChangeVoucherCodeInput = (event) => {
    const input = event.target.value;
    setVoucherCode(input);
    if(allowKeyBoard) {
      keyboardVoucher.current.setInput(input);
    }
  };

  const onChangeSearchInput = (event) => {
    const input = event.target.value;
    setSearchCountryCode(input);
    if(allowKeyBoard) {
      keyboardSearch.current.setInput(input);
    }
  };

  const onChangeInputMobile = (event) => {
    let input = event.target.value;
    if (/^0/.test(input)) {
      input = input.replace(/^0+/, '');
    }
    setValidMobile(input);
    if(allowKeyBoard) {
      keyboardMobile.current.setInput(input);
    }
    setErrors({ ...errors, validMobile: null });
  };

  const enterCardNumber = (event) => {
    setCardInfoTemp({
      ...cardInfoTemp,
      bank_card_number: event['target']['value'] || '',
      bank_card_number_with_space: event['target']['value'] || '',
    });
    console.log('cardInfoTemp', cardInfoTemp);
  };

  const validateBankOffer = (offer) => {
    console.log('offer', offer);
    if (!cardInfoTemp?.bank_card_number) {
    } else {
      setCardInfoTemp({
        ...cardInfoTemp,
        is_loading: true,
        offerDetails: offer
      });

      setBankLoading(true);

      let payload = {
        reservation_id,
        card_number: cardInfoTemp?.bank_card_number ?? null,
        offer_id: offer?.os_id ?? null,
        callback: setBankLoading,
        cardInfo: cardInfoTemp,
      };
      dispatch(validateBankOfferAction(payload));
      console.log('cardInfoTemp', cardInfoTemp);
    }
  };

  const onSelectCountryCode = (country) => {
    setSelectedCountry(country);
    let mobCode = country.country_mob_code;
    dispatch({ type: SET_CUST_MOB_CODE, payload: mobCode });
  };

  const makePayment = async (paymentMode) => {
    let guest_id = localStorage.getItem('guest_id');
    let mapped_cust_id = localStorage.getItem('mapped_cust_id');
    let payload = { reservation_id, guest_id, mapped_cust_id };

    console.log(
      'makePayment',
      paymentMode,
      'payload',
      payload,
      'redirectData',
      redirectData,
    );
    if (redirectData !== null) return;

    let data = null;

    // cc avenue
    if (paymentMode.pm_id === 18) {
      data = await dispatch(makeCCAvenuePayment(payload));
    }

    // cc avenue
    if (paymentMode.pm_id === 43) {
      data = await dispatch(makeMasterCardAsfPayment(payload));
    }

    if (paymentMode.pm_id === 44) {
      data = await dispatch(makeMasterCardScPayment(payload));
    }

    if (paymentMode.pm_id === 45) {
      data = await dispatch(makeBenefitCardPayment(payload));
    }

    if(paymentMode.pm_id === 48) {
      data = await dispatch(makeMasterCardApplePay(payload));
    }
    // khalti pay
    if (paymentMode.pm_id === 24) {
      data = await dispatch(makeKhaltiPayment(payload));
    }

    // eseva
    if (paymentMode.pm_id === 28) {
      data = await dispatch(makeEsevaPayment(payload));
      data = {
        ...data,
        url: `${data.url}${data.qs || ''}`,
      };
    }

    // ime pay
    if (paymentMode.pm_id === 36) {
      data = await dispatch(makeIMEPayment(payload));
    }

    // Al Rajhi Bank
    if (paymentMode.pm_id === 22) {
      data = await dispatch(makeAlrajhiPayment(payload));
    }

    console.log('redirectData', data);
    if (data) setredirectData(data);
  };

  const makeonlyFnBPayment = async (paymentMode) => {
    console.log('Only Fnb Api Hit');
    let guest_id = localStorage.getItem('guest_id');
    let mapped_cust_id = localStorage.getItem('mapped_cust_id');

    let guest_detail = JSON.parse(localStorage.getItem('guest_detail'));
    let guest_email = guest_detail?.guest_email;
    let guest_mobile = guest_detail?.guest_mobile;
    let guest_country_code = guest_detail?.guest_country_code;

    let location = window.location.origin;

    let FnbintentPayload = {
      guest_id: mapped_cust_id ? 0 : guest_id,
      cust_id: mapped_cust_id || 0,
      redirect_url: web_origin?.go_value
        ? `${web_origin.go_value}/reservation-details/${reservation_id}`
        : `${location}/final-ticket`,
      fnbItemList: selectedFnbItems,
      // fnbDeliveryTime: null,
      // fnb_delivery_time: null,
      cinema_id: cinema_id,
      onlyFnb: true,
      currency_id: curr_id,
      ft_currency_name: curr_code,
      ft_net_amount: fnbPrice?.totalWithFnb - fnbPrice?.taxWithFnb,
      ft_total_amount: fnbPrice?.totalWithFnb,
      fst_booking_source: 'KIOSK',
      booking_source_id: 3,
      ft_actual_date: '',
      ft_order_status: 3,
      payment_mode_id: paymentMode.pm_id,
      payment_mode: paymentMode.pm_payment_mode,
      offer: {
        // t_offer_price: offerPrice?.TotalOfferPrice || 0,
        // t_offer_total_price: offerPrice?.actualTotalGross || 0,
        // is_on_net_amount: null,
        isOfferApplied: offerPrice?.isOfferApplied || false,
      },

      customer_details: {
        uname: '',
        email: guest_email,
        mobile: guest_mobile,
        country_code: guest_country_code,
      },
      ft_reservation_id: reservation_id,
      token: TOKEN,
    };

    console.log(
      'makePayment',
      paymentMode,
      'FnbintentPayload',
      FnbintentPayload,
      'redirectData',
      redirectData,
    );
    if (redirectData !== null) return;

    let data = null;

    // cc avenue
    if (paymentMode.pm_id === 18) {
      data = await BookingService.CreateCCAvenueFnbOnly(FnbintentPayload);
      if (data) {
        setredirectData(data?.data?.data);
        localStorage.setItem('fop_id', data?.data?.data?.fop_id);
      }
    }

    // khalti pay
    if (paymentMode.pm_id === 24) {
      data = await BookingService.CreateKhaltiFnbOnlyIntent(FnbintentPayload);
      if (data && data && data.data.status) {
        setredirectData(data?.data);
        localStorage.setItem('fop_id', data?.data?.fop_id);
      } else {
        toast.error(data?.data?.message || 'Somthing Went Wrong!');
      }
    }

    // Al Rajhi Bank
    if (paymentMode.pm_id === 22) {
      FnbintentPayload.redirect_url = `${
        fnb_origin.go_value || ''
      }/orderConfirmation`;

      data = await BookingService.CreateAlrajhiFnbOnly(FnbintentPayload);
      if (data) {
        setredirectData(data?.data?.data);
        localStorage.setItem('fop_id', data?.data?.data?.fop_id);
      }
    }

    console.log('redirectData', data);
  };

  useEffect(() => {
    console.log('selectedFnbItems', selectedFnbItems)
  }, [selectedFnbItems])

  useEffect(() => {
    dispatch(fetchPaymentModes());

    if (mode === 2) {
      let payload = {
        fnb_reservation_id: reservation_id,
      };

      FnbService.GetReserverFnbItems(payload)
        .then((saved) => {
          console.log('getcall', { ...saved?.data });
          let result = saved?.data;
          setfnbPrice(result?.fnb_price);
          // props.history.push(`/checkout-one/${reservation_id}`);
        })
        .catch((e) => {
          toast.error(`Can not save FnB Items`);
          props.history.push(`/`);
        });
    }

    let payloadNew = {
      // cinema_id: cinema_id.toString(),
      cinema_id: cinema_id.toString(),
      movie_details_id: showDetails?.movie_details_id || null,
      screen_id: showDetails?.screen_id || null,
      seat_type_id: seatSelected?.screen_seat_type_id || null,
      show_actual_date_time: showDetails?.ss_actual_start_date_time,
      show_date_time: showDetails?.ss_actual_start_date_time,
      tt_id: null,
    };

    FnbService.GetAvailablePromotonsAndOffers(payloadNew)
      .then((response) => {
        // console.log('offerdata', { ...response?.data });
        let result = response?.data;
        let OfferData = [];
        if (mode === 2) {
          OfferData = result?.Records.filter((x) => {
            return x?.offer_redeem_at_concession == 'Y';
          });
        } else if (mode === 3 || mode === 1) {
          OfferData = result?.Records.filter((x) => {
            return x?.offer_redeem_at_online == 'Y';
          });
        }

        setFnbOfferData(OfferData);
        console.log(OfferData, 'OfferDataOfferData');

        // props.history.push(`/checkout-one/${reservation_id}`);
      })
      .catch((e) => {
        toast.error(`No Offers Available`);
        // props.history.push(`/`);
      });
  }, []);

  useEffect(() => {
    if(mode !== 2) {
      dispatch(fetchReservationDetailsBrij({reservation_id}));
    }
  }, [reservation_id])

  useEffect(() => {
    console.log('reservationDetails', reservationDetails)
    let internet_hanling_fees = !!reservationDetails?.internet_handling_fee ? parseFloat(reservationDetails?.internet_handling_fee) : 0;
    setConvFees(internet_hanling_fees);
  }, [reservationDetails])

  useEffect(() => {
    console.log({ checkBoookingCount });
  }, [checkBoookingCount]);

  useEffect(() => {
    searchCountryFunction();
  }, [searchCountryCode]);

  useInterval(
    (ops) => {
      setCheckBookingCount(checkBoookingCount + 1);
      // check booking
      if (mode !== 2) {
        BookingService.CheckBookingStatus(reservation_id)
          .then(({ data }) => {
            console.log({ data });
            if (data?.booking_code) {
              dispatch({ type: SET_BOOKING_ID, payload: data?.booking_code });
              dispatch({ type: SET_BOOKING_CODE, payload: data?.booking_code });
              // dispatch(setBookingDetails(data));
              history.push(`/final-ticket`);
            }
          })
          .catch((e) => {});
      }

      if (mode == 2) {
        BookingService.CheckFnbBookingStatus(reservation_id)
          .then(({ data }) => {
            console.log({ data });
            if (data?.status && data?.orderStatus == 1) {
              history.push(`/final-ticket`);
            }
          })
          .catch((e) => {});
      }
    },

    // Delay in milliseconds or null to stop it
    redirectData && redirectData.url ? 1000 * 10 : null,
  );

  // mode 2 which is fnb only will not have showDetails
  if (mode !== 2 && !showDetails?.MovieContent) {
    console.log('Invalid Movie contents', { showDetails, mode });
    history.push('/');
    return null;
  }

  return (
    <>
      <MovieHeader
        onClick={() => {
          setShowKeyboard(false);
          setShowKeyboardMobile(false);
          setshowSearchKeyboard(false);
        }}
      />
      <Container fluid>
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            setShowKeyboard(false);
            setShowKeyboardMobile(false);
            setshowSearchKeyboard(false);
          }}
        >
          {/* <GoBack to="prev" /> */}
        </div>
        <Row
          className="checkout-page mb-2 zoom_7"
          onClick={() => {
            setShowKeyboard(false);
            setShowKeyboardMobile(false);
            setshowSearchKeyboard(false);
          }}
        >
          <Col className="col-md-12 col-xl-8">
            <>
              {mode !== 2 && (
                <>
                  {' '}
                  <div className="pb-3">
                    <h5 className="heading">{t('checkout.Tickets')}</h5>
                  </div>
                  <Row className="ticket-header">
                    <Col className="col-3">
                      <img
                        src={
                          getLangSpecificAttribute(
                            showDetails.MovieContent,
                            lang_id,
                            'artwork',
                          ) || dummy_image
                        }
                        alt="title"
                        className="img-fluid"
                      />
                    </Col>
                    <Col className="col-9 ml-n4">
                      <h4>
                        {getLangSpecificAttribute(
                          showDetails.MovieContent,
                          lang_id,
                          'mc_title',
                          true,
                        ) ||
                          showDetails?.original_title_of_movie ||
                          showDetails?.movie_title}
                      </h4>
                      <div className="grid-50 pt-3">
                        <div>
                          <p>{t('common.Date')}</p>
                          <h4>{moment(showDetails?.ss_start_date).format('LL')}</h4>
                        </div>
                        <div>
                          <div className="session-timer">
                          <span>
                            {' '}
                            {timer.minutes}:
                            {timer.seconds < 10 ? '0' + timer.seconds : timer.seconds}
                          </span>
                          <img src={clock} className={''} />
                        </div>
                        </div>
                        <div>
                          <p>{t('seatlayout_page.Show Details')}</p>
                          <h4>
                            {showDetails?.screen_name},{' '}
                            {moment(
                              showDetails?.ss_start_show_time,
                              'HH:mm',
                            ).format('hh:mm A')}
                          </h4>
                        </div>
                        {/* <div>
          <p>Voucher Code</p>
          <h4>
           ABCDS (20% OFF)
        </h4>
         </div> */}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          </Col>
          <Col className={`${mode == 2 ? 'col-12 mt-2' : 'col-md-12 col-xl-12 col-12'}`}>
            <div className="brds-10 ticket-right fix_height_container_2">
              {mode !== 2 && (
                <div className="ticket-right-box pb-2">
                  <h5 className="title pt-4 pb-3">{t('checkout.Tickets')}</h5>
                  {ConvFees && ConvFees > 0 && (<div className="grid-half pt-2">
                    <h5 className="left">Conv Fees.</h5>
                    <h5 className="right font-weight-bold">
                      {' '}
                      {curr_code}{' '}
                      {(
                        ConvFees
                      ).toFixed(3)}
                    </h5>
                  </div>)}
                  <div className="grid-half pt-2">
                    <h5 className="left">{seats_string}</h5>
                    <h5 className="right font-weight-bold">
                      {' '}
                      {curr_code}{' '}
                      {(
                        selectedTicketPrice.priceInCents  + ConvFees -
                        selectedTicketPrice.totalWithFnb
                      ).toFixed(3)}
                    </h5>
                  </div>
                  <div className="grid-half pt-2">
                    <h5 className="left">{t('Discount')}</h5>
                    <h5 className="right font-weight-bold">
                      {' '}
                      {offerPrice?.TotalOfferPrice > 0 ? '-' : ''} {curr_code}{' '}
                      {offerPrice?.TotalOfferPrice
                        ? offerPrice?.TotalOfferPrice
                        : '0.00'}
                    </h5>
                  </div>
                </div>
              )}
              {selectedFnbItems.length > 0 && (
                <div className="ticket-right-box pb-2">
                  <h5 className="title pt-4 pb-3">
                    {t('checkout.Food and Beverages')}
                  </h5>
                  {selectedFnbItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="grid-half pt-2" key={index}>
                        <h5 className="left">
                          {item.item_name} (x{item.Quantity})
                        </h5>
                        <h5 className="right font-weight-bold">
                          {curr_code}{' '}
                          {(item.fp_amount * item.Quantity).toFixed(3)}
                        </h5>
                      </div>
                    </React.Fragment>
                  ))}

                  {
                    <React.Fragment>
                    <div className="grid-half pt-2">
                      <h5 className="left">
                        VAT
                      </h5>
                      <h5 className="right font-weight-bold">
                        {curr_code}{' '}
                        {parseFloat(selectedFnbItems.reduce(
                          (accumulator, { fp_total_amount, fp_amount }) =>
                            accumulator + parseFloat(fp_total_amount - fp_amount),
                          0
                        )).toFixed(3)}
                      </h5>
                    </div>
                  </React.Fragment>
                  }

                  {
                    <React.Fragment>
                    <div className="grid-half pt-2">
                      <h4 className="left">
                        GRAND TOTAL
                      </h4>
                      <h4 className="right font-weight-bold">
                      {curr_code}{' '}
                      {offerPrice?.isOfferApplied
                        ? parseFloat(
                            selectedTicketPrice.priceInCents  + ConvFees -
                              offerPrice?.TotalOfferPrice,
                          ).toFixed(3)
                        : parseFloat(selectedTicketPrice.priceInCents  + ConvFees).toFixed(3)}{' '}
                      </h4>
                    </div>
                  </React.Fragment>
                  }

                  {mode == 2 && (
                    <div className="grid-half pt-2">
                      <h5 className="left">{t('Discount')}</h5>
                      <h5 className="right font-weight-bold">
                        {' '}
                        {offerPrice?.TotalOfferPrice > 0 ? '-' : ''} {curr_code}{' '}
                        {offerPrice?.TotalOfferPrice > 0
                          ? offerPrice?.TotalOfferPrice
                          : '0.00'}
                      </h5>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <div className="checkout-footer">
          <div
            // onHide={handleClose}
            // size="lg"
            // aria-labelledby="contained-modal-title-vcenter"
            className="border-top"
          >
            <div className="brds-8 pt-2">
              <div className="my-4 col-12">
                {false && (
                  <>
                    <article className="row flex-column align-items-center">
                      <section className="col-6 d-flex align-items-center">
                        <input
                          type="text"
                          placeholder="Voucher Code"
                          className="rounded-lg mb-1 form-control mr-2"
                          value={voucherCode}
                          onChange={(e) => onChangeVoucherCodeInput(e)}
                          onClick={(e) => onChangeVoucherCodeInput(e)}
                          onFocus={() => {
                            setShowKeyboardVoucher(allowKeyBoard);
                            setShowKeyboard(false);
                            setShowKeyboardMobile(false);
                            setshowSearchKeyboard(false);
                          }}
                          disabled={voucherDiscount > 0}
                        />

                        {voucherDiscount == 0 && (
                          <button
                            type="button"
                            className="blue-btn text-nowrap px-5 ml-2"
                            onClick={validateVoucher}
                          >
                            {t('apply').toUpperCase()}
                          </button>
                        )}
                      </section>

                      <section className="col-12">
                        {showKeyboardVoucher && (
                          <KeyboardWrapper
                            keyboardRef={keyboardVoucher}
                            setShowKeyboardVoucher={setShowKeyboardVoucher}
                            onChange={(e) => {
                              console.log('fsddfsdfsd', e);
                              setVoucherCode(e);
                            }}
                          />
                        )}
                      </section>
                    </article>
                  </>
                )}

                <div>
                  <button
                    className="blue-btn px-5 w-100"
                    onClick={() => {
                      setShowOfferModal(true);
                    }}
                  >
                    {'CHECK OFFERS'}
                  </button>
                </div>
                <article className="row flex-column align-items-center">
                  <section className="col-8">
                    <h4 className="mb-2 font-weight-bold pt-4">
                      {t('checkout.Enter your contact details')}
                    </h4>
                  </section>

                  <section className="col-8">
                    <input
                      type="email"
                      placeholder="Email"
                      className="rounded-lg mb-1 form-control"
                      value={validEmail}
                      onChange={(e) => onChangeInput(e)}
                      onClick={(e) => onChangeInput(e)}
                      onFocus={() => {
                        setShowKeyboard(allowKeyBoard);
                        setShowKeyboardVoucher(false);
                        setShowKeyboardMobile(false);
                        setshowSearchKeyboard(false);
                      }}
                    />
                  </section>

                  <section className="col-12">
                    {showKeyboard && (
                      <KeyboardWrapper
                        keyboardRef={keyboard}
                        setShowKeyboard={setShowKeyboard}
                        onChange={(e) => {
                          console.log('fsddfsdfsd', e);
                          setValidEmail(e);
                        }}
                      />
                    )}
                  </section>

                  <section className="section col-8 mb-4">
                    {errors && errors.validEmail && (
                      <span className="form-error-text d-block">
                        {t('checkout.Enter a valid email address')}
                      </span>
                    )}
                  </section>
                </article>
                <article className="row flex-column align-items-center">
                  <section className="col-8">
                    <div className="d-flex">
                      <>
                        <Dropdown
                          onClick={() => {
                            setShowKeyboard(false);

                            setShowKeyboardMobile(false);
                          }}
                          className=" mr-3 code"
                        >
                          <Dropdown.Toggle
                            className="dropdownClassic"
                            name="country"
                            id="country"
                          >
                            {cust_mob_code ? cust_mob_code : 'Country Code'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdownItems">
                            <span className="d-flex flex-column align-items-center">
                              <input
                                className="kiosk-input"
                                type="number"
                                value={searchCountryCode}
                                onChange={(e) => onSearchCountry(e.target.value)}
                                placeholder="Search Country Code"
                                onChange={(e) => onChangeSearchInput(e)}
                                onClick={(e) => onChangeSearchInput(e)}
                                onFocus={() => {
                                  setshowSearchKeyboard(allowKeyBoard);
                                  setShowKeyboard(false);
                                  setShowKeyboardMobile(false);
                                  setShowKeyboardVoucher(false);
                                }}
                              />
                              {showSearchKeyboard && (
                                <KeyboardWrapper
                                  keyboardRef={keyboardSearch}
                                  onChange={(e) => onSearchCountry(e)}
                                  keyboardOptions={keyboardOptions}
                                  setshowSearchKeyboard={setshowSearchKeyboard}
                                />
                              )}
                            </span>
                            {countriesCopy?.current.map((country, indx) => (
                              <Dropdown.Item
                                key={indx}
                                value={country.country_id}
                                onSelect={() => onSelectCountryCode(country)}
                              >
                                {country.country_mob_code}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        {submitted && !cust_mob_code && (
                          <span className="form-error-text d-block">
                            {t('checkout.Please select a country code')}
                          </span>
                        )}
                      </>

                      <input
                        type="number"
                        maxLength={10}
                        id="mobileCheckout"
                        placeholder="Mobile No."
                        className="rounded-lg mb-1 form-control"
                        value={validMobile}
                        onChange={(e) => onChangeInputMobile(e)}
                        onClick={(e) => onChangeInputMobile(e)}
                        onFocus={() => {
                          setShowKeyboardMobile(allowKeyBoard);
                          setShowKeyboard(false);
                          setshowSearchKeyboard(false);
                          setShowKeyboardVoucher(false);
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            console.log('Only numbers are allowed');
                            event.preventDefault();
                            toast.error('Only numbers are allowed', {
                              toastId: 'numeric',
                            });
                          }
                        }}
                      />
                    </div>
                  </section>
                  <section className="col-12">
                    {showKeyboardMobile && (
                      <KeyboardWrapper
                        keyboardRef={keyboardMobile}
                        enableDigitValidation={true}
                        keyboardOptions={keyboardOptions}
                        setShowKeyboardMobile={setShowKeyboardMobile}
                        onChange={(e) => {
                          console.log(e, 'fsddfsdfdfs');
                          let array = [
                            '0',
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                          ];
                          var str = e;
                          // if (str.match(/^[a-zA-Z]*$/g)) {
                          //   console.log("number only");
                          // }
                          // var res = str.replace(/\D/g, "");
                          var res1 = str.replace(/\D/g, '');
                          var res = res1.replace(/^0+/g, '');
                          // res = res.replace(/^0/g, "");
                          console.log(res, 'dfsdfsdfsd');
                          // if (array.indexOf(e.toString()) >= 0) {
                          setValidMobile(parseInt(res));
                          // }
                          // console.log("fsddfsdfsd", typeof e)
                        }}
                      />
                    )}
                  </section>
                  <section className="col-6">
                    {errors && errors.validMobile && (
                      <span className="form-error-text d-block">
                        {t('checkout.Enter a valid mobile number')}
                      </span>
                    )}
                  </section>
                </article>
              </div>
            </div>
          </div>

          <div
            className="checkout-footer-bottom"
            onClick={() => {
              setShowKeyboard(false);
              setShowKeyboardMobile(false);
            }}
          >
            <div>
              <h6 className="title">{t('fnb_page.Total Payable')}</h6>
              {mode === 2 && (
                <h4 className="font-weight-bold">
                  {curr_code}{' '}
                  {offerPrice?.isOfferApplied
                    ? offerPrice?.actualTotalGross
                    : selectedTicketPrice.priceInCents.toFixed(3)}{' '}
                </h4>
              )}
              {mode !== 2 && (
                <h4 className="font-weight-bold">
                  {curr_code}{' '}
                  {offerPrice?.isOfferApplied
                    ? +parseFloat(
                        selectedTicketPrice.priceInCents + ConvFees -
                          offerPrice?.TotalOfferPrice,
                      ).toFixed(3)
                    : parseFloat(selectedTicketPrice.priceInCents + ConvFees).toFixed(3)}{' '}
                </h4>
              )}
              {offerPrice?.isOfferApplied && (
                <>
                  <span className="pr-2 fw-reg">Offer Applied:</span>
                  <span className="pr-2 fw-reg">
                    <span className="item_name_class">&#10003;</span>
                    {offerPrice.offer_title}
                  </span>
                  <span className="" onClick={onRemoveOffer}><img src={deleteIcon} alt="Remove" height="25" width="25" /></span>
                </>
              )}
            </div>
            <div className='d-flex justify-content-center align-items-center'>
            <button className="blue-btn px-5 mr-3" onClick={onCancel}>
            {t('Cancel')}
          </button>
              <button className="blue-btn px-5" onClick={onProceed}>
                {t('PAY')}
              </button>
            </div>
          </div>
        </div>
        {/* <div className="seat-footer col-12">
     <div className="py-4 row">
      <div className="col-md-8">
       <div className="d-flex px-3 br-1">
        <div><h4 className="f-grey">Total Payable</h4><h1 className="fw-bold">INR 320.00 </h1><h5>INR Taxes: 41.74 </h5></div></div></div><div className="d-flex justify-content-center align-items-center col-md-4">
     <span className="blue-btn btn-lg px-5">PROCEED</span></div></div></div> */}
      </Container>
      <Modal
        show={showTimeoutPopup}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-time-up"
        backdrop="static"
      >
        <Modal.Header closeButton={false}>
          <p className="kumbh fw-bold frxl-36 frl-32 frm-28 frs-24 ls-4">
            {t('timeout.time-up')}
          </p>
        </Modal.Header>
        <Modal.Body>
          <p className="kumbh fw-reg frxl-24 frl-20 frs-16">
            {t('timeout.session-expired')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <span
            onClick={() => history.push('/')}
            className="blue-btn mxh-47 px-3 frxl-18 frl-16 frs-14"
          >
            {t('timeout.go-back')}
          </span>
        </Modal.Footer>
      </Modal>

      {/* Modal to show payment methods */}
      <Modal
        show={showPaymentModesModal}
        onHide={() => setShowPaymentModesModal(false)}
        centered
        className="modal-time-up checkout_modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton={true}>
          <p className="kumbh fw-bold frxl-36 frl-32 frm-28 frs-24 ls-4 mt-2">
            {t('checkout.Select Payment Method')}
          </p>
        </Modal.Header>
        <Modal.Body className="checkout_modal__payment_modes">
          {filteredPaymentModes &&
            filteredPaymentModes.length > 0 &&
            filteredPaymentModes
              .filter((pm) => {
                if (selectedPaymentMode === null) return true;
                return selectedPaymentMode.pm_id == pm.pm_id;
              })
              .filter((pm) => {
                if (selectedPaymentMode === null) return true;
                return selectedPaymentMode.pm_id == pm.pm_id;
              })
              .map((paymentMode, index) => (
                <span
                  key={index}
                  onClick={() => {
                    if (
                      selectedPaymentMode &&
                      selectedPaymentMode.pm_id !== paymentMode.pm_id
                    ) {
                      return;
                    }

                    if (selectedPaymentMode === null) {
                      setSetSelectedPaymentMode(paymentMode);

                      mode != 2
                        ? paymentModeHandler(paymentMode, paymentMode.pm_id)
                        : paymentModeFnbHandler(paymentMode, paymentMode.pm_id);
                    }
                  }}
                  className="blue-btn mxh-47 px-3 frxl-18 frl-16 frs-14 paymentModeBtn"
                >
                  {paymentMode?.name_on_btn?.toUpperCase()}
                  {<img
                    className="payment-multipage-img-mobile-style"
                    height={paymentMode?.img_height}
                    width={paymentMode?.img_width}
                    src={paymentMode?.card_image}
                  />}
                  {loader && selectedBtn == paymentMode.pm_id && (
                    <span className="pl-2">
                      <Spinner animation="border" role="status" className="spinner">
                        <span className="sr-only">{t('Loading')}</span>
                      </Spinner>
                    </span>
                  )}
                </span>
              ))}
          {paymentModes.length === 0 && (
            <span className="blue-btn mxh-47 px-3 frxl-18 frl-16 frs-14">
              {t('no_payment_mode_txt').toUpperCase()}
            </span>
          )}

          {redirectData && redirectData?.url && (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <QRCode
                level={'Q'}
                size={256}
                includeMargin={true}
                value={redirectData.url}
              />

              <p className="kumbh fw-reg lh-1 mt-2 qrPayMsg">Scan this QR Code With Your Phone's Camera to Proceed With the Payment.</p>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Offer Modal*/}
      <Modal
        show={showOfferModal}
        onHide={() => setShowOfferModal(false)}
        centered
        className="modal-time-up checkout_modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton={true}>
          <p className="kumbh fw-bold frxl-36 frl-32 frm-28 frs-24 ls-4 mt-2">
            {t('Select Offer')}
          </p>
        </Modal.Header>
        <Modal.Body className="checkout_modal__payment_modes">
          <div>
            {fnbOfferData &&
              fnbOfferData.length &&
              fnbOfferData.length > 0 &&
              fnbOfferData?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-block">

                      {
                        item?.is_bank_offer == 'Y' && item?.offer_title?.includes('Standard Chartered') && (
                          <img
                        className="payment-multipage-img-mobile-style"
                        height={40}
                        width={30}
                        src={masterCardStandardCharteredLogo}
                  />
                        ) 
                      }
                        <span class="offer_txt">{item?.offer_title}</span>

                        {/* <p>{item?.offerContent[0]?.offer_description}</p> */}
                      </div>
                      {
                        !cardInfoTemp?.is_valid && item?.is_bank_offer == 'Y' ? (
                          <button
                          className={cardInfoTemp?.bank_card_number?.length < 6 ? 'btnInactive blue-btn p2-5' : 'blue-btn p2-5'}
                          disabled={cardInfoTemp?.bank_card_number?.length  < 6}
                          onClick={() => {
                            validateBankOffer(item);
                          }}
                        >
                          {!!cardInfoTemp?.is_valid
                            ? 'Validated'
                            : 'Validate'}
                        </button>
                        ) : (
                          <button
                        className={offerPrice?.isOfferApplied && offerPrice?.oc_id == item?.oc_id ? 'activeBtn blue-btn p2-5' : 'blue-btn p2-5'}
                        onClick={() => VerifyFnbOfferData(item)}
                      >
                         {
                          offerPrice?.isOfferApplied && offerPrice?.oc_id == item?.oc_id ? 'Applied' : 'Apply'
                        }
                      </button>
                        )
                      }
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.offerContent[0]?.offer_description,
                      }}
                    ></div>
                    {item?.is_bank_offer == 'Y' &&
                                                !offerPrice?.isOfferApplied && (
                                                  <div className="bank-card-input">
                                                    <div className="card_number_input">
                                                      <label>Card Number </label>
                                                      <input
                                                        name="card_number"
                                                        id="card_number"
                                                        className="cardInputBank"
                                                        maxLength="6"
                                                        placeholder="000 000"
                                                        type="text"
                                                        onInput={enterCardNumber}
                                                      />
                                                      <span className="mask-text">
                                                        XXXX XXXX
                                                      </span>
                                                    </div>
                                                    {!!cardInfoTemp?.bank_validate_msg && (
                                                      <span
                                                        className={
                                                          !!cardInfoTemp?.is_valid
                                                            ? 'text-success card_validMsg'
                                                            : 'text-danger card_validMsg'
                                                        }
                                                      >
                                                        {!!cardInfoTemp?.bank_validate_msg &&
                                                          cardInfoTemp?.bank_validate_msg}
                                                      </span>
                                                    )}
                                                  </div>
                                                )}
                    {fnbOfferData.length > 1 && (
                      <hr
                        style={{ borderBottom: '1px solid var(--bn-brand-color)' }}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            {fnbOfferData.length <= 0 && (
              <span className="blue-btn mxh-47 px-3 frxl-18 frl-16 frs-14">
                No Offer Available
              </span>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withNamespaces()(CheckoutOne);
