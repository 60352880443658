import React, { useContext, useEffect } from 'react';
// import { globalConfigContext } from '@context/GlobalConfigContext';
import i18n from '../../plugins/i18n';
import { Link } from 'react-router-dom';
// import moment from 'moment';
// import 'moment/locale/ar';
import { withNamespaces } from 'react-i18next';
import { Row, Container, Col, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setKioskSelectedLanguage } from '../../store/global/globalActions';
import { useHistory } from 'react-router-dom';
import { getLangISOCode } from '@helper/formatting';
import moment from 'moment';
// import 'moment/locale/de';
// import 'moment/locale/fr';
// import 'moment/locale/it';

const MovieHeader = ({ t, changeLanguageFunction }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const { arabic, setArabic } = useContext(globalConfigContext);
  const arabic = false;
  const global = useSelector((state) => state.global);
  const {
    globalOptions,
    cinema: { cinema_name },
    selected_language: { lang_name },
    globalSettings: { org_logo_url },
  } = global;

  let kiosk_selected_lang_obj = JSON.parse(
    localStorage.getItem('kiosk_selected_language'),
  );
  let kiosk_selected_lang_id = kiosk_selected_lang_obj.lang_id;

  let multiLangCinemaNameArr = global?.cinema?.translation_data;

  let multiLangCinemaName = multiLangCinemaNameArr?.filter((x) => {
    return x?.langaugeId == kiosk_selected_lang_id;
  });

  const globalOptionsLanguages = globalOptions.find((go) => go.go_id === 30);
  let languages = [];
  if (globalOptionsLanguages && globalOptionsLanguages !== null) {
    const globalLanguages = JSON.parse(globalOptionsLanguages.go_value);
    languages = globalLanguages;

    languages.sort((a, b) => {
      return (a.lang_id || 0) - (b.lang_id || 0);
    });
  } else {
    history.push(`/`);
  }

  // useEffect(() => {
  // 	if (lang && lang === 'ar') {
  // 		document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
  // 		setArabic(true);
  // 	} else {
  // 		document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
  // 		setArabic(false);
  // 	}
  // 	i18n.changeLanguage(lang ? lang : 'en');
  // }, []);

  const changeLanguage = (lng) => {
    console.log('languages: ', languages);
    console.log('lang change');

    // if (lng === "ar") {
    //   window.localStorage.setItem("lang", "ar");
    //   document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    // } else {
    //   window.localStorage.setItem("lang", "en");
    //   document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    // }
    dispatch(setKioskSelectedLanguage(lng));
    let lang = lng.iso_2 ? lng.iso_2.toLowerCase() : getLangISOCode(lng.lang_name);
    i18n.changeLanguage(lang);
    moment.locale('en');
    // changeLanguageFunction();
    // window.location.reload();
  };

  return (
    <Container fluid>
      <div>
        <div className="movie_header">
          <Link to="/" className="logo">
            <img src={org_logo_url} />
          </Link>
          <span className="big_text">
            {multiLangCinemaName && multiLangCinemaName[0]?.cinema_name
              ? multiLangCinemaName[0]?.cinema_name
              : cinema_name}
          </span>
          {/* <div className="dropdown_div">
						<select
							onChange={(e) => changeLanguage(e.target.value)}
							className="dropdown"
							name="language"
							id="language"
						>
							<option value="en" selected={!lang || lang === 'en'}>
								{t('common.ENGLISH')}
							</option>
							<option value="ar" selected={lang && lang === 'ar'}>
								{t('common.ARABIC')}
							</option>
						</select>
					</div> */}
          {languages?.length > 1 ? (
            <Dropdown>
              <Dropdown.Toggle
                className="dropdownClassic"
                // onChange={(e) => changeLanguage(e.target.value)}
                name="language"
                id="language"
              >
                {lang_name?.toUpperCase()}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdownItems">
                {languages?.map((lang, i) => (
                  <Dropdown.Item
                    key={i}
                    value={lang?.lang_id}
                    onSelect={() => changeLanguage(lang)}
                  >
                    {lang?.lang_name?.toUpperCase()}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <div className="single-lang">
              {languages[0]?.lang_name?.toUpperCase()}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default withNamespaces()(MovieHeader);
