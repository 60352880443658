import React, { useEffect, useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  fetchGlobalSettings,
  setKioskSelectedLanguage,
  login,
} from '../store/global/globalActions';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import i18n from '../plugins/i18n';


const StartupPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [language_change, setLanguageChange] = useState(null);
  const global = useSelector((state) => state.global);

  useEffect(() => {
    dispatch(setKioskSelectedLanguage("en"));
    moment.locale("en");
    i18n.changeLanguage("en");
    history.push(`/movies`);
  }, [])


  return (
    <>
      
    </>
  );
};
export default withNamespaces()(StartupPage);
