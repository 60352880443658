import {
  FETCH_SEAT_LAYOUT_LOADING,
  FETCH_SEAT_LAYOUT_SUCCESS,
  FETCH_SEAT_LAYOUT_ERROR,
  FETCH_SEAT_TYPE_LOADING,
  FETCH_SEAT_TYPE_SUCCESS,
  FETCH_SEAT_TYPE_ERROR,
  FETCH_SHOW_DETAILS_SUCCESS,
  SET_SEAT_SELECTED,
  SET_RESERVATION_ID,
  SET_SEAT_SELECT_ERROR,
  SET_TRANSACTION_ID,
  SET_TRANSACTION_ERROR,
  CLEAR_BOOKING_STATE,
  SET_TICKET_PRICE,
  RESET_RESERVATION_ID,
  SET_GUEST_ID,
  START_TIMER,
  STOP_TIMER,
  SET_BOOKING_FEE_DATA,
  SET_ADDITIONAL_DATA,
  CLEAR_ERRORS,
  CLEAR_SELECTED_SEATS,
  SET_SEATS_STRING,
  SET_SESSION_ID,
  SET_PRICE,
  SELECT_FNB_ITEM,
  FETCH_FNB_ITEMS,
  FETCH_FNB_ITEMS_ERROR,
  SET_BOOKING_ID,
  PICKUP_TICKET_LOADING,
  PICKUP_TICKET_SUCCESS,
  PICKUP_TICKET_CONTENT_SUCCESS,
  PICKUP_TICKET_ERROR,
  RESET_PICKUP_TICKET_ERROR,
  CLEAR_STORE,
  ON_SET_COMPLETE_RATE_CARD,
  ON_SET_COMPLETE_NEW_TOTAL_AMOUNT,
  SET_FT_ID,
  CLEAR_FNB_STATE,
  SET_PAYMENT_MODES,
  SET_BOOKING_DETAILS,
  SET_FNB_LOADER,
  SET_BOOKING_CODE,
  CLEAR_SELECTED_FNB_ITEMS,
  SET_FNB_BOOKING_DETAILS,
  FETCH_VOUCHERS,
  FETCH_VOUCHERS_SUCCESS,
  FETCH_VOUCHERS_ERROR,
  VERIFY_VOUCHER,
  VERIFY_VOUCHER_SUCCESS,
  VERIFY_VOUCHER_ERROR,
  UPDATE_SEAT_LAYOUT,
  FETCH_RESERVATION_DETAILS_V2,
  FETCH_RESERVATION_DETAILS_V2_SUCCESS,
  FETCH_RESERVATION_DETAILS_V2_ERROR,
  SET_CARD_INFO,
  CLEAR_CARD_INFO
} from './bookingTypes';
import cloneDeep from 'lodash/cloneDeep';
import round from 'lodash/round';
import { clearTimer } from './bookingActions';
import { SET_CHECKOUT_TIMER } from '../global/globalTypes';
import { getValueFromGlobalOptions } from '@helper/utils';
import { FETCH_SEAT_LAYOUT_SUCCESS_ON_DEMAND } from '../seatLayout/seatLayoutTypes';

const initialState = {
  seat_layout_loading: false,
  seat_layout: [],
  seat_types: [],
  seat_layout_error: null,
  show_details: [],
  seats_selected: [],
  fnb_items: [],
  selected_fnb_items: [],
  vista_seats_selected: [],
  reservation_id: null,
  guest_id: null,
  seat_select_error: null,
  transaction_id: null,
  transaction_error: null,
  ticketPrice: null,
  totalAmount: null,
  netAmount: null,
  selectedTicketPrice: {
    priceInCents: 0,
    taxInCents: 0,
    totalWithFnb: 0,
    taxWithFnb: 0,
  },
  voucherAmount: 0,
  bookingFee: 0,
  timer: {
    minutes: 9,
    seconds: 59,
  },
  showTimeoutPopup: false,
  additionalData: {},
  seats_string: null,
  session_id: null,
  booking_id: null,
  bookingCode: null,
  pickup_ticket: null,
  pickup_ticket_error: false,
  isCompleteRateCard: false,
  seatGroupAll: null,
  ft_id: null,
  payment_modes: [],
  bookingDetails: null,
  fnb_loader: false,
  fnbBookingDetails: null,
  vouchers: [],
  successfulVoucherCode: null,
  pickup_ticket_content: [],
  reserve_fnb_items: [],
  reservationDetails: [],
  reserve_selected_fnb_items: [],
  cardInfo: {
    bank_card_number: "",
    bank_card_number_with_space: "",
    bank_validate_msg: "",
    is_loading: false,
    is_valid: false,
    bank: null,
  },
  reserve_fnb_price: {
    total_price: 0,
    total_tax: 0,
  },
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_NOW_PLAYING_MOVIES':
      return {
        ...state,
        ...initialState,
      };
    case FETCH_RESERVATION_DETAILS_V2:
    case FETCH_RESERVATION_DETAILS_V2_ERROR:
      return {
        ...state,
        reservationDetails: null,
      };
    case FETCH_RESERVATION_DETAILS_V2_SUCCESS:
      return {
        ...state,
        reservationDetails: action.payload,
      };
      case SET_CARD_INFO: {
        return {
          ...state,
          cardInfo: action.payload,
        };
      }
      case CLEAR_CARD_INFO: {
        return {
          ...state,
          cardInfo: {
            bank_card_number: "",
            bank_card_number_with_space: "",
            bank_validate_msg: "",
            is_loading: false,
            is_valid: false,
            bank: null,
          },
        };
      }
    case FETCH_VOUCHERS:
    case FETCH_VOUCHERS_ERROR:
      return {
        ...state,
        vouchers: [],
      };

    case FETCH_VOUCHERS_SUCCESS:
      return {
        ...state,
        vouchers: action.payload,
      };

    case VERIFY_VOUCHER:
    case VERIFY_VOUCHER_ERROR:
      return {
        ...state,
        successfulVoucherCode: null,
      };

    case VERIFY_VOUCHER_SUCCESS:
      return {
        ...state,
        successfulVoucherCode: action.payload.offer,
        voucherAmount: action.payload.voucher.offerPrice,
        selectedTicketPrice: {
          ...state.selectedTicketPrice,
          priceInCents: action.payload.voucher.priceInCents,
        },
      };
    case SET_FNB_BOOKING_DETAILS:
      return { ...state, fnbBookingDetails: action.payload };
    case SET_FT_ID: {
      return { ...state, ft_id: action.payload };
    }
    case ON_SET_COMPLETE_RATE_CARD:
      console.log('action.payloadON_SET_COMPLETE_RATE_CARD :>> ', action.payload);
      return {
        ...state,
        isCompleteRateCard: action.payload.status,
        seatGroupAll: action.payload.seatGroup,
      };
    case ON_SET_COMPLETE_NEW_TOTAL_AMOUNT:
      console.log(
        'action.payload ON_SET_COMPLETE_NEW_TOTAL_AMOUNT:>> ',
        action.payload,
      );
      return {
        ...state,
        selectedTicketPrice: {
          priceInCents: action.payload.totalAmount,
          taxInCents:
            action.payload.netAmount !== 0
              ? action.payload.totalAmount - action.payload.netAmount
              : state.selectedTicketPrice.taxInCents,
          totalWithFnb: state.selectedTicketPrice.totalWithFnb,
          taxWithFnb: state.selectedTicketPrice.taxWithFnb,
        },
        netAmount: action.payload.netAmount,
        totalAmount: action.payload.totalAmount,
      };
    case RESET_RESERVATION_ID:
      return {
        ...state,
        reservation_id: null,
      };
    case FETCH_SEAT_LAYOUT_LOADING:
      return {
        ...state,
        seat_layout_loading: true,
      };
    case FETCH_SEAT_LAYOUT_SUCCESS:
      return {
        ...state,
        seat_layout_loading: false,
        seat_layout: action.payload,
        seat_layout_error: null,
        selectedTicketPrice: {
          priceInCents: 0,
          taxInCents: 0,
          totalWithFnb: 0,
          taxWithFnb: 0,
        },
      };
    case FETCH_SEAT_LAYOUT_SUCCESS_ON_DEMAND:
      return {
        ...state,
        seat_layout_loading: false,
        seat_layout: action.payload,
        seat_layout_error: null,
        selectedTicketPrice: {
          priceInCents: 0,
          taxInCents: 0,
          totalWithFnb: 0,
          taxWithFnb: 0,
        },
      };
    case FETCH_SEAT_LAYOUT_ERROR:
      return {
        ...state,
        seat_layout_loading: false,
        seat_layout: [],
        seat_types: [],
        seat_layout_error: action.payload,
        selectedTicketPrice: {
          priceInCents: 0,
          taxInCents: 0,
          totalWithFnb: 0,
          taxWithFnb: 0,
        },
      };
    case UPDATE_SEAT_LAYOUT: {
      let newSeatLayout = [...state.seat_layout];
      let seats_selected = state.seats_selected;
      let { sl_id, status, globalOptions } = action.payload;
      let isCovidSeatEnabled = getValueFromGlobalOptions(
        globalOptions,
        'ENABLE_COVID_SEAT_LAYOUT',
      );

      console.log('inside update seat layout reducer =>> ', action.payload);

      for (let seatType of newSeatLayout) {
        for (let [rowIndex, row] of seatType.seatsFinal.entries()) {
          for (let [seatIndex, seat] of row.entries()) {
            if (seat.sl_id == sl_id) {
              console.log('found the seat to update');
              seat.seat_reserve_type_id = status;
              seat.covidBlockedTemp = false;

              if (isCovidSeatEnabled === 'Y') {
                if (seatType.seatsFinal[rowIndex][seatIndex - 1]) {
                  let leftSeat = seatType.seatsFinal[rowIndex][seatIndex - 1];
                  let isNotSelected =
                    seats_selected.findIndex((x) => x.sl_id == leftSeat.sl_id) ===
                    -1;
                  if (
                    ![8, 2, 13].includes(leftSeat.seat_reserve_type_id) &&
                    isNotSelected
                  ) {
                    leftSeat.covidBlockedTemp =
                      status === 8
                        ? true
                        : seatType.seatsFinal[rowIndex][seatIndex - 2]
                            ?.seat_reserve_type_id === 8
                        ? true
                        : false;
                  } else if (leftSeat.seat_reserve_type_id == 8) {
                    leftSeat.covidBlockedTemp = false;
                    seat.covidBlockedTemp = status === 1 ? true : false;
                  }
                }

                if (seatType.seatsFinal[rowIndex][seatIndex + 1]) {
                  let rightSeat = seatType.seatsFinal[rowIndex][seatIndex + 1];
                  if (
                    ![8, 2, 13].includes(rightSeat.seat_reserve_type_id) &&
                    seats_selected.findIndex((x) => x.sl_id == rightSeat.sl_id) ===
                      -1
                  ) {
                    rightSeat.covidBlockedTemp =
                      status === 8
                        ? true
                        : seatType.seatsFinal[rowIndex][seatIndex + 2]
                            ?.seat_reserve_type_id === 8
                        ? true
                        : false;
                  } else if (rightSeat.seat_reserve_type_id == 8) {
                    rightSeat.covidBlockedTemp = false;
                    seat.covidBlockedTemp = status === 1 ? true : false;
                  }
                }
              }
            }
          }
        }
      }

      return {
        ...state,
        seat_layout: newSeatLayout,
      };
    }
    case FETCH_SEAT_TYPE_LOADING:
      return {
        ...state,
        seat_layout_loading: true,
      };
    case FETCH_SEAT_TYPE_SUCCESS:
      return {
        ...state,
        seat_types: action.payload,
      };
    case FETCH_SEAT_TYPE_ERROR:
      return {
        ...state,
        seat_layout_loading: false,
        seat_layout: [],
        seat_types: [],
        seat_layout_error: action.payload,
        selectedTicketPrice: {
          priceInCents: 0,
          taxInCents: 0,
          totalWithFnb: 0,
          taxWithFnb: 0,
        },
      };
    case FETCH_SHOW_DETAILS_SUCCESS:
      return {
        ...state,
        show_details: action.payload,
      };
    case SET_SEATS_STRING:
      return {
        ...state,
        seats_string: action.payload,
      };
    case SET_SESSION_ID:
      return {
        ...state,
        session_id: action.payload,
      };
    case SET_SEAT_SELECTED:
      const { SeatTypes } = action.payload;
      const newSeatLayout = [...state.seats_selected];
      const existingSeat = state.seats_selected.some(
        (seat) =>
          seat.sl_seat_name === SeatTypes.sl_seat_name &&
          seat.sl_row_num === SeatTypes.sl_row_num &&
          seat.sl_col_num === SeatTypes.sl_col_num &&
          seat.sl_id === SeatTypes.sl_id &&
          seat.seat_reserve_type_id === SeatTypes.seat_reserve_type_id &&
          seat.srt_id === SeatTypes.srt_id,
      );
      console.log(
        '🚀 ~ file: bookingReducer.js ~ line 104 ~ bookingReducer ~ existingSeat',
        existingSeat,
      );

      // let taxAmount =
      //   SeatTypes.seat_price - SeatTypes.seat_net_price.toFixed(3);
      if (existingSeat) {
        let finalIndex = state.seats_selected.findIndex(
          (seat) =>
            seat.sl_seat_name === SeatTypes.sl_seat_name &&
            seat.sl_row_num === SeatTypes.sl_row_num &&
            seat.sl_col_num === SeatTypes.sl_col_num &&
            seat.sl_id === SeatTypes.sl_id &&
            seat.seat_reserve_type_id === SeatTypes.seat_reserve_type_id &&
            seat.srt_id === SeatTypes.srt_id,
        );
        SeatTypes.SeatSelected = !SeatTypes.SeatSelected;
        newSeatLayout.splice(finalIndex, 1);
        // state.selectedTicketPrice.priceInCents -= SeatTypes.seat_price;
        // state.selectedTicketPrice.taxInCents -= taxAmount;
      } else {
        SeatTypes.SeatSelected = !SeatTypes.SeatSelected;
        newSeatLayout.push(SeatTypes);
        // state.selectedTicketPrice.priceInCents += SeatTypes.seat_price;
        // state.selectedTicketPrice.taxInCents += taxAmount;
      }
      return {
        ...state,
        seats_selected: newSeatLayout,
      };
    case FETCH_FNB_ITEMS:
      console.log('FnbReducer: FETCH_FNB_ITEMS', action.payload);
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach((item) => {
          item.fp_total_amount = parseFloat(item.fp_total_amount);
          item.fp_amount = parseFloat(item.fp_amount);
        });
      }
      return {
        ...state,
        reserve_fnb_items: window.structuredClone(action.payload),
        fnb_items: action.payload,
        fnb_loader: false,
      };
    case FETCH_FNB_ITEMS_ERROR:
      console.log('FnbReducer: FETCH_FNB_ITEMS_ERROR');
      return {
        ...state,
        fnb_items: [],
        reserve_fnb_items: [],
        fnb_items_error: action.payload,
        fnb_loader: false,
      };
    case SET_FNB_LOADER:
      return {
        ...state,
        fnb_loader: true,
      };
    case CLEAR_FNB_STATE: {
      return {
        ...state,
        fnb_items: [],
        fnb_items_error: null,
        selected_fnb_items: [],
        ft_id: null,
        reserve_fnb_items: [],
        reserve_selected_fnb_items: [],
        reserve_fnb_price: {
          total_price: 0,
          total_tax: 0,
        },
      };
    }
    case CLEAR_SELECTED_FNB_ITEMS:
      state.fnb_items.forEach((x) => {
        x.Quantity = 0;
      });

      let new_fnb_items = window.structuredClone([...state.reserve_fnb_items]);

      new_fnb_items.forEach((x) => {
        x.Quantity = 0;
        x.quantity = 0;
      });

      return {
        ...state,
        selected_fnb_items: [],
        selectedTicketPrice: {
          priceInCents: 0,
          taxInCents: 0,
          totalWithFnb: 0,
          taxWithFnb: 0,
        },
        voucherAmount: 0,
        bookingFee: 0,
        reserve_fnb_items: new_fnb_items,
        reserve_selected_fnb_items: [],
        reserve_fnb_price: {
          total_price: 0,
          total_tax: 0,
        },
      };

    case 'SELECT_FNB_ITEM_FOR_RESERVE': {
      const { item } = action.payload;

      let new_fnb_items = window.structuredClone([...state.reserve_fnb_items]);
      let new_selected_items = window.structuredClone([
        ...state.reserve_selected_fnb_items,
      ]);
      let new_fnb_price = window.structuredClone({ ...state.reserve_fnb_price });

      const itemIndex = new_fnb_items.findIndex(
        (selectedItem) =>
          item.fc_id === selectedItem.fc_id &&
          item.fc_parent_id === selectedItem.fc_parent_id &&
          item.fp_id === selectedItem.fp_id &&
          item.fp_amount === selectedItem.fp_amount &&
          item.item_id === selectedItem.item_id,
      );
      const selectedItemIndex = new_selected_items.findIndex(
        (selectedItem) =>
          item.fc_id === selectedItem.fc_id &&
          item.fc_parent_id === selectedItem.fc_parent_id &&
          item.fp_id === selectedItem.fp_id &&
          item.fp_amount === selectedItem.fp_amount &&
          item.item_id === selectedItem.item_id,
      );

      let tax = round(item.fp_total_amount - item.fp_amount, 4);

      if (itemIndex !== -1) {
        if (action.payload.type == 'add') {
          if (selectedItemIndex == -1) {
            new_fnb_items[itemIndex]['Quantity'] = 1;
            new_fnb_items[itemIndex]['quantity'] = 1;
            let itemCopy = cloneDeep(new_fnb_items[itemIndex]);
            itemCopy.total_price = round(item.fp_total_amount, 4);
            itemCopy.total_tax = tax;
            new_fnb_price.total_price = round(
              new_fnb_price.total_price + item.fp_total_amount,
              4,
            );
            new_fnb_price.total_tax = round(new_fnb_price.total_tax + tax, 4);
            new_selected_items.push(itemCopy);
          } else {
            new_fnb_items[itemIndex].Quantity++;
            new_fnb_items[itemIndex].quantity++;
            new_selected_items[selectedItemIndex].Quantity++;
            new_selected_items[selectedItemIndex].quantity++;
            new_selected_items[selectedItemIndex].total_price = round(
              new_selected_items[selectedItemIndex].total_price +
                item.fp_total_amount,
              4,
            );
            new_selected_items[selectedItemIndex].total_tax = round(
              new_selected_items[selectedItemIndex].total_tax + tax,
              4,
            );
            new_fnb_price.total_price = round(
              new_fnb_price.total_price + item.fp_total_amount,
              4,
            );
            new_fnb_price.total_tax = round(new_fnb_price.total_tax + tax, 4);
          }
        } else if (action.payload.type == 'sub') {
          if (
            new_selected_items[selectedItemIndex] &&
            new_selected_items[selectedItemIndex].Quantity &&
            new_selected_items[selectedItemIndex].Quantity > 0
            // &&
            // new_fnb_items[itemIndex] &&
            // new_fnb_items[itemIndex].Quantity &&
            // new_fnb_items[itemIndex].Quantity > 0
          ) {
            new_fnb_items[itemIndex]['Quantity']--;
            new_fnb_items[itemIndex]['quantity']--;
            new_selected_items[selectedItemIndex].Quantity--;
            new_selected_items[selectedItemIndex].quantity--;
            new_selected_items[selectedItemIndex].total_price = round(
              new_selected_items[selectedItemIndex].total_price -
                item.fp_total_amount,
              4,
            );
            new_selected_items[selectedItemIndex].total_tax = round(
              new_selected_items[selectedItemIndex].total_tax - tax,
              4,
            );

            new_fnb_price.total_price = round(
              new_fnb_price.total_price - item.fp_total_amount,
              4,
            );
            new_fnb_price.total_tax = round(new_fnb_price.total_tax - tax, 4);

            if (new_selected_items[selectedItemIndex]['Quantity'] == 0) {
              new_fnb_items[itemIndex]['Quantity'] = 0;
              new_fnb_items[itemIndex]['quantity'] = 0;
              new_selected_items.splice(selectedItemIndex, 1);
            }
          }
        }
      }

      return {
        ...state,
        reserve_fnb_items: new_fnb_items,
        reserve_selected_fnb_items: new_selected_items,
        reserve_fnb_price: new_fnb_price,
      };
    }

    case SELECT_FNB_ITEM:
      console.log('FnbReducer: SELECT_FNB_ITEM');
      const { item } = action.payload;
      const itemIndex = state.fnb_items.findIndex(
        (selectedItem) =>
          item.fc_id === selectedItem.fc_id &&
          item.fc_parent_id === selectedItem.fc_parent_id &&
          item.fp_id === selectedItem.fp_id &&
          item.fp_amount === selectedItem.fp_amount &&
          item.item_id === selectedItem.item_id,
      );
      const selectedItemIndex = state.selected_fnb_items.findIndex(
        (selectedItem) =>
          item.fc_id === selectedItem.fc_id &&
          item.fc_parent_id === selectedItem.fc_parent_id &&
          item.fp_id === selectedItem.fp_id &&
          item.fp_amount === selectedItem.fp_amount &&
          item.item_id === selectedItem.item_id,
      );

      let priceWithTax;
      let priceWithoutTax;
      let tax = item.fp_total_amount - item.fp_amount;

      //   if (itemIndex !== -1) {
      //     priceWithTax = newFnbItems[itemIndex]["PriceInCents"];
      //     priceWithoutTax = parseFloat((priceWithTax / (1 + taxRate)).toFixed(3));
      //     tax = parseFloat((priceWithTax - priceWithoutTax).toFixed(3));
      //   }

      if (itemIndex !== -1) {
        if (action.payload.type == 'add') {
          if (selectedItemIndex == -1) {
            state.fnb_items[itemIndex]['Quantity'] = 1;
            let itemCopy = cloneDeep(state.fnb_items[itemIndex]);
            itemCopy.priceInCents = item.fp_total_amount;
            itemCopy.taxInCents = tax;
            state.selectedTicketPrice.priceInCents += item.fp_total_amount;
            state.selectedTicketPrice.taxInCents += tax;
            state.selectedTicketPrice.totalWithFnb += item.fp_total_amount;
            state.selectedTicketPrice.taxWithFnb += tax;
            state.selected_fnb_items.push(itemCopy);
          } else {
            state.fnb_items[itemIndex].Quantity++;
            state.selected_fnb_items[selectedItemIndex].Quantity++;
            state.fnb_items[itemIndex].priceInCents += item.fp_total_amount;
            state.fnb_items[itemIndex].taxInCents += tax;
            state.selectedTicketPrice.priceInCents += item.fp_total_amount;
            state.selectedTicketPrice.taxInCents += tax;
            state.selectedTicketPrice.totalWithFnb += item.fp_total_amount;
            state.selectedTicketPrice.taxWithFnb += tax;
          }
        } else if (action.payload.type == 'sub') {
          if (
            state.selected_fnb_items[selectedItemIndex] &&
            state.selected_fnb_items[selectedItemIndex].Quantity &&
            state.selected_fnb_items[selectedItemIndex].Quantity > 0 &&
            state.fnb_items[itemIndex] &&
            state.fnb_items[itemIndex].Quantity &&
            state.fnb_items[itemIndex].Quantity !== 0
          ) {
            state.fnb_items[itemIndex]['Quantity']--;
            state.selected_fnb_items[selectedItemIndex].Quantity--;
            state.fnb_items[itemIndex].priceInCents -= item.fp_total_amount;
            state.fnb_items[itemIndex].taxInCents -= tax;

            state.selectedTicketPrice.priceInCents -= item.fp_total_amount;
            state.selectedTicketPrice.taxInCents -= tax;
            state.selectedTicketPrice.totalWithFnb -= item.fp_total_amount;
            state.selectedTicketPrice.taxWithFnb -= tax;
            // fnbPrice.priceInCents -= priceWithTax;
            // fnbPrice.taxesInCents -= tax;

            if (state.selected_fnb_items[selectedItemIndex]['Quantity'] == 0) {
              // show_modifiers_popup = false;
              // show_recipe_items_popup = false;
              state.selected_fnb_items.splice(selectedItemIndex, 1);
              // newFnbItems[itemIndex].Modifiers = [];
            }
          }
        }
      }

      return {
        ...state,
      };

    // let selectedTicketPrice = Object.assign({}, state.selectedTicketPrice);
    // if (
    //   newSeatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex].hasOwnProperty("SeatSelected") && newSeatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex].SeatSelected
    // ) {
    //   newSeatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex]["SeatSelected"] = false;

    // } else {
    //   newSeatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex]["SeatSelected"] = true;
    //   selectedTicketPrice.TicketTypeCode = newSeatLayout[seatTypeIndex].TicketTypeCode;
    //   // newSeatLayout[seatTypeIndex].PriceInCents
    //   // newSeatLayout[seatTypeIndex].TaxInCents
    // }
    // const seatName =
    //   state.seat_layout[seatTypeIndex].Rows[rowIndex].PhysicalName +
    //   "-" +
    //   state.seat_layout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex].Id;
    // const newSeats = [...state.seats_selected];
    // const newVistaSeats = [...state.vista_seats_selected];
    // if (newSeats.indexOf(seatName) > -1) {
    //   newSeats.splice(newSeats.indexOf(seatName), 1);
    //   newVistaSeats.splice(newVistaSeats.indexOf(Position), 1);
    // } else {
    //   newSeats.push(seatName);
    //   newVistaSeats.push(Position);
    // }
    // return {
    //   ...state,
    //   selectedTicketPrice: selectedTicketPrice,
    //   seat_layout: newSeatLayout,
    //   seats_selected: newSeats,
    //   vista_seats_selected: newVistaSeats,
    //   seat_select_error: null
    // };
    case SET_RESERVATION_ID:
      return {
        ...state,
        reservation_id: action.payload,
      };
    case SET_BOOKING_ID:
      return {
        ...state,
        booking_id: action.payload,
      };
    case SET_BOOKING_CODE:
      return {
        ...state,
        bookingCode: action.payload,
      };
    case SET_SEAT_SELECT_ERROR:
      return {
        ...state,
        seat_select_error: action.payload,
      };
    case SET_TRANSACTION_ID:
      return {
        ...state,
        transaction_id: action.payload,
        transaction_error: null,
      };
    case SET_TRANSACTION_ERROR:
      return {
        ...state,
        transaction_id: null,
        transaction_error: action.payload,
      };
    case CLEAR_BOOKING_STATE:
      return {
        ...state,
        seat_layout_loading: false,
        seat_layout: [],
        seat_layout_error: null,
        vista_seats_selected: [],
        reservation_id: null,
        seat_select_error: null,
        transaction_error: null,
        selectedTicketPrice: {
          priceInCents: 0,
          taxInCents: 0,
          totalWithFnb: 0,
          taxWithFnb: 0,
        },
      };
    case SET_TICKET_PRICE:
      return {
        ...state,
        ticketPrice: action.payload,
      };
    case SET_GUEST_ID:
      return {
        ...state,
        guest_id: action.payload,
      };
    case SET_CHECKOUT_TIMER:
      state.timer.minutes = parseInt(action.payload) - 1;
      return {
        ...state,
      };
    case START_TIMER:
      let seconds = state.timer.seconds - 1;
      let minutes = state.timer.minutes;
      let showPopup = false;
      if (seconds == -1) {
        if (minutes == 0) {
          seconds = 0;
          minutes = 0;
          showPopup = true;
          clearInterval(action.payload);
        } else {
          seconds = 59;
          minutes = minutes - 1;
        }
      }
      var timer = Object.assign({}, state.timer);
      timer = {
        minutes: minutes,
        seconds: seconds,
      };
      return {
        ...state,
        timer: timer,
        showTimeoutPopup: showPopup,
      };
    case STOP_TIMER:
      var _timer = Object.assign({}, state.timer);
      _timer = {
        minutes: 8,
        seconds: 59,
      };
      return {
        ...state,
        timer: _timer,
      };
    case SET_BOOKING_FEE_DATA:
      return {
        ...state,
        bookingFee: action.payload,
      };
    case SET_ADDITIONAL_DATA:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          ...action.payload,
        },
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        seat_layout_error: null,
      };
    case CLEAR_SELECTED_SEATS:
      return {
        ...state,
        seats_selected: [],
        vista_seats_selected: [],
      };
    case SET_PRICE:
      return {
        ...state,
        selectedTicketPrice: action.payload,
      };
    case PICKUP_TICKET_SUCCESS:
      return {
        ...state,
        pickup_ticket: action.payload,
        pickup_ticket_error: false,
      };
    case PICKUP_TICKET_CONTENT_SUCCESS:
      return {
        ...state,
        pickup_ticket_content: action.payload,
      };
    case PICKUP_TICKET_ERROR:
      return {
        ...state,
        pickup_ticket_error: true,
        pickup_ticket: null,
      };
    case RESET_PICKUP_TICKET_ERROR:
      return {
        ...state,
        pickup_ticket_error: false,
        pickup_ticket: null,
      };
    case CLEAR_STORE:
      clearTimer();
      return {
        ...initialState,
        selected_fnb_items: [],
        reserve_selected_fnb_items: [],
      };
    case SET_PAYMENT_MODES: {
      return {
        ...state,
        payment_modes: action.payload,
      };
    }
    case SET_BOOKING_DETAILS: {
      return {
        ...state,
        bookingDetails: action.payload,
      };
    }
    default:
      return state;
  }
};

export default bookingReducer;
