import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_ERROR,
  FETCH_MODIFIER_GROUP,
  FETCH_MODIFIER_GROUP_ERROR,
  FETCH_MODIFIFERS,
  FETCH_MODIFIERS_ERROR,
  CLOSE_MODIFIER_POPUP,
  SELECT_MODIFIER,
  FNB_RESERVATION_DONE,
  SET_RECIPE_ITEMS,
  SELECT_MODIFIER_UNDER_RECIPES,
  CLOSE_RECIPE_MODIFIER_POPUP,
  REMOVE_FNB_ITEM,
  REDIRECT_TO_CHECKOUT,
  CLEAR_FNB_STATE,
} from './foodAndBeveragesTypes';
import { SET_PRICE } from '../booking/bookingTypes';
import { CLEAR_FOOD_AND_BEVERAGES_STATE } from '../booking/bookingTypes';
import cloneDeep from 'lodash/cloneDeep';
//initial state of user
const initialState = {
  fnb_categories: [],
  fnb_categories_error: null,
  fnb_items: [],
  fnb_items_error: null,
  selected_fnb_items: [],
  fnb_price: {
    priceInCents: 0,
    taxesInCents: 0,
  },
  fnb_modifiers_group: [],
  fnb_modifiers_group_error: null,
  fnb_modifiers: [],
  fnb_modifiers_error: null,
  show_modifiers_popup: false,
  selected_modifiers: [],
  selected_fnb_item_id: null,
  current_fnb_item: {},
  modifier_price: {
    priceInCents: 0,
    taxesInCents: 0,
  },
  fnbReservationDone: false,
  recipe_items: [],
  show_recipe_items_popup: false,
  redirect_to_checkout: false,
};

const taxRate = 0.15;

const fnbReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES:
      console.log('FnbReducer: FETCH_CATEGORIES');
      return {
        ...state,
        fnb_categories: action.payload,
      };
    case REDIRECT_TO_CHECKOUT:
      return {
        ...state,
        redirect_to_checkout: true,
      };
    case FETCH_CATEGORIES_ERROR:
      console.log('FnbReducer: FETCH_CATEGORIES_ERROR');
      return {
        ...state,
        fnb_categories: [],
        fnb_categories_error: action.payload,
      };

    case REMOVE_FNB_ITEM:
      console.log('FnbReducer: REMOVE_FNB_ITEM');
      let fnbPrice2 = Object.assign({}, state.fnb_price);
      let fnbModifierPrice = { ...state.modifier_price };

      let newSelectedItems2 = [...state.selected_fnb_items];
      const selectedItemIndex2 = newSelectedItems2.findIndex(
        (item) => item.Id === action.payload.fnbId,
      );

      let newFnbItems2 = [...state.fnb_items];
      const itemIndex2 = newFnbItems2.findIndex(
        (item) => item.Id === action.payload.fnbId,
      );

      if (selectedItemIndex2 !== -1) {
        let fnbPriceWithTax =
          newFnbItems2[itemIndex2]['PriceInCents'] *
          newFnbItems2[itemIndex2]['Quantity'];
        let fnbTax = parseFloat(
          (fnbPriceWithTax - fnbPriceWithTax / (1 + taxRate)).toFixed(3),
        );

        fnbPrice2.priceInCents -= fnbPriceWithTax;
        fnbPrice2.taxesInCents -= fnbTax;
        fnbModifierPrice.priceInCents -=
          newSelectedItems2[selectedItemIndex2]['modifiersPriceInCents'];
        fnbModifierPrice.taxesInCents -=
          newSelectedItems2[selectedItemIndex2]['modifiersTaxesInCents'];
        newFnbItems2[itemIndex2]['Quantity'] = 0;

        newSelectedItems2.splice(selectedItemIndex2, 1);
        newFnbItems2[itemIndex2].Modifiers = [];
      }

      return {
        ...state,
        fnb_items: newFnbItems2,
        selected_fnb_items: newSelectedItems2,
        fnb_price: fnbPrice2,
        modifier_price: fnbModifierPrice,
        show_modifiers_popup: false,
        show_recipe_items_popup: false,
      };

    case FETCH_MODIFIER_GROUP:
      console.log('FnbReducer: FETCH_MODIFIER_GROUP');
      return {
        ...state,
        fnb_modifiers_group: action.payload,
      };
    case FETCH_MODIFIER_GROUP_ERROR:
      console.log('FnbReducer: FETCH_MODIFIER_GROUP_ERROR');
      return {
        ...state,
        fnb_modifiers_group: [],
        fnb_modifiers_group_error: action.payload,
      };
    case FETCH_MODIFIFERS:
      console.log('FnbReducer: FETCH_MODIFIFERS');
      let newModifiers = [...action.payload];
      let currentFnbItem = {};
      let selectedfnbId = newModifiers.filter((x) => {
        return x.concession_id;
      });
      if (selectedfnbId && selectedfnbId.length > 0) {
        selectedfnbId = selectedfnbId[0].concession_id;
      }
      currentFnbItem = state.selected_fnb_items.find((x) => x.Id == selectedfnbId);

      newModifiers = newModifiers.map((x) => {
        x['Quantity'] = 0;
        x['priceInCents'] = 0;
        x['taxesInCents'] = 0;
        return x;
      });

      return {
        ...state,
        fnb_modifiers: newModifiers,
        show_modifiers_popup: true,
        selected_fnb_item_id: selectedfnbId,
        current_fnb_item: currentFnbItem,
      };
    case FETCH_MODIFIERS_ERROR:
      console.log('FnbReducer: FETCH_MODIFIERS_ERROR');
      return {
        ...state,
        fnb_modifiers: [],
        fnb_modifiers_error: action.payload,
      };
    case CLOSE_MODIFIER_POPUP:
      console.log('FnbReducer: CLOSE_MODIFIER_POPUP');
      return {
        ...state,
        show_modifiers_popup: false,
      };
    case SELECT_MODIFIER:
      console.log('FnbReducer: SELECT_MODIFIER');
      let newSelectedFnbItems = [...state.selected_fnb_items];
      // let newSelectedFnbItems = cloneDeep(state.selected_fnb_items)
      let selectedFnbitemIndex = newSelectedFnbItems.findIndex(
        (x) => x.Id == state.selected_fnb_item_id,
      );

      let modifiersLength =
        newSelectedFnbItems[selectedFnbitemIndex].Modifiers.length;
      console.log('SELECT_MODIFIER -> modifiersLength', modifiersLength);
      let modifierIndex = newSelectedFnbItems[selectedFnbitemIndex].Modifiers[
        modifiersLength - 1
      ].findIndex((x) => x.Id == action.payload.modifierId);

      let newFnbModifiers = [...state.fnb_modifiers];
      let fnbModifierIndex = newFnbModifiers.findIndex(
        (x) => x.Id == action.payload.modifierId,
      );

      let modifierPrice = Object.assign({}, state.modifier_price);

      let modifierPriceWithTax = newFnbModifiers[fnbModifierIndex].PriceInCents;
      let modifierPriceWithoutTax = parseFloat(
        (modifierPriceWithTax / (1 + taxRate)).toFixed(3),
      );
      let modifierTax = parseFloat(
        (modifierPriceWithTax - modifierPriceWithoutTax).toFixed(3),
      );

      if (selectedFnbitemIndex > -1) {
        if (action.payload.type == 'add') {
          newFnbModifiers[fnbModifierIndex].Quantity++;
          if (modifierIndex === -1) {
            let modifierCopy = cloneDeep(newFnbModifiers[fnbModifierIndex]);
            modifierCopy.priceInCents = modifierPriceWithTax;
            modifierCopy.taxesInCents = modifierTax;

            newSelectedFnbItems[selectedFnbitemIndex].Modifiers[
              modifiersLength - 1
            ].push(modifierCopy);
          } else {
            newSelectedFnbItems[selectedFnbitemIndex].Modifiers[
              modifiersLength - 1
            ][modifierIndex].Quantity++;

            newSelectedFnbItems[selectedFnbitemIndex].Modifiers[
              modifiersLength - 1
            ][modifierIndex].priceInCents += modifierPriceWithTax;
            newSelectedFnbItems[selectedFnbitemIndex].Modifiers[
              modifiersLength - 1
            ][modifierIndex].taxesInCents += modifierTax;
          }

          newSelectedFnbItems[selectedFnbitemIndex]['modifiersPriceInCents'] +=
            modifierPriceWithTax;
          newSelectedFnbItems[selectedFnbitemIndex]['modifiersTaxesInCents'] +=
            modifierTax;

          modifierPrice.priceInCents += modifierPriceWithTax;
          modifierPrice.taxesInCents += modifierTax;
          // if (modifierIndex == -1) {
          //     newFnbModifiers[fnbModifierIndex].priceInCents += newFnbModifiers[fnbModifierIndex].PriceInCents;
          //     newFnbModifiers[fnbModifierIndex].taxesInCents += newFnbModifiers[fnbModifierIndex].TaxInCents;
          //     newSelectedFnbItems[selectedFnbitemIndex].Modifiers.push(newFnbModifiers[fnbModifierIndex])
          // }
        } else if (action.payload.type == 'sub') {
          console.log('sub');
          if (modifierIndex > -1) {
            console.log('found modifier index');
            if (
              newSelectedFnbItems[selectedFnbitemIndex].Modifiers[
                modifiersLength - 1
              ][modifierIndex].Quantity != 0
            ) {
              newSelectedFnbItems[selectedFnbitemIndex].Modifiers[
                modifiersLength - 1
              ][modifierIndex].Quantity--;
              newFnbModifiers[fnbModifierIndex].Quantity--;

              newSelectedFnbItems[selectedFnbitemIndex]['modifiersPriceInCents'] -=
                modifierPriceWithTax;
              newSelectedFnbItems[selectedFnbitemIndex]['modifiersTaxesInCents'] -=
                modifierTax;

              newSelectedFnbItems[selectedFnbitemIndex].Modifiers[
                modifiersLength - 1
              ][modifierIndex].priceInCents -= modifierPriceWithTax;
              newSelectedFnbItems[selectedFnbitemIndex].Modifiers[
                modifiersLength - 1
              ][modifierIndex].taxesInCents -= modifierTax;

              modifierPrice.priceInCents -= modifierPriceWithTax;
              modifierPrice.taxesInCents -= modifierTax;

              if (
                newSelectedFnbItems[selectedFnbitemIndex].Modifiers[
                  modifiersLength - 1
                ][modifierIndex].Quantity == 0
              ) {
                console.log('splicing here');
                newSelectedFnbItems[selectedFnbitemIndex].Modifiers[
                  modifiersLength - 1
                ].splice(modifierIndex, 1);
                console.log(
                  newSelectedFnbItems[selectedFnbitemIndex].Modifiers[
                    modifiersLength - 1
                  ],
                );
              }
            }
          }
        }
      }
      return {
        ...state,
        selected_fnb_items: newSelectedFnbItems,
        fnb_modifiers: newFnbModifiers,
        modifier_price: modifierPrice,
      };
    case SELECT_MODIFIER_UNDER_RECIPES:
      console.log('FnbReducer: SELECT_MODIFIER_UNDER_RECIPES');
      const { modifier_id, modifier_group_id, recipe_id, type } = action.payload;

      let new_recipe_items = [...state.recipe_items];
      const selectedRecipeItem = new_recipe_items.find(
        (recipe_item) => recipe_item.Id === recipe_id,
      );
      const selectedModifierGroup = selectedRecipeItem.ModifierGroups.find(
        (modifierGroup) => modifierGroup.Id === modifier_group_id,
      );
      const selectedModifier = selectedModifierGroup.Modifiers.find(
        (modifier) => modifier.Id === modifier_id,
      );

      let recipeModifierPriceWithTax = selectedModifier.PriceInCents;
      let recipeModifierPriceWithoutTax = parseFloat(
        (recipeModifierPriceWithTax / (1 + taxRate)).toFixed(3),
      );
      let recipeModifierTax = parseFloat(
        (recipeModifierPriceWithTax - recipeModifierPriceWithoutTax).toFixed(3),
      );

      let newSelectedFnbItems2 = [...state.selected_fnb_items];
      let selectedFnbitemIndex2 = newSelectedFnbItems2.findIndex((x) => {
        return x.Id == state.selected_fnb_item_id;
      });

      let modifiersLength2 =
        newSelectedFnbItems2[selectedFnbitemIndex2].Modifiers.length;
      const selectedModifierIndex2 = newSelectedFnbItems2[
        selectedFnbitemIndex2
      ].Modifiers[modifiersLength2 - 1].findIndex(
        (modifier) => modifier.Id === modifier_id,
      );

      let newSelectedFnb2 = [...state.selected_fnb_items];

      let modifier_price = Object.assign({}, state.modifier_price);

      if (selectedFnbitemIndex2 > -1) {
        if (type === 'add') {
          selectedModifier.Quantity++;
          if (selectedModifierIndex2 !== -1) {
            newSelectedFnb2[selectedFnbitemIndex2].Modifiers[modifiersLength2 - 1][
              selectedModifierIndex2
            ].Quantity++;

            newSelectedFnb2[selectedFnbitemIndex2].Modifiers[modifiersLength2 - 1][
              selectedModifierIndex2
            ].priceInCents += recipeModifierPriceWithTax;
            newSelectedFnb2[selectedFnbitemIndex2].Modifiers[modifiersLength2 - 1][
              selectedModifierIndex2
            ].taxesInCents += recipeModifierTax;
          } else {
            let modifierCopy = cloneDeep(selectedModifier);
            modifierCopy.priceInCents = recipeModifierPriceWithTax;
            modifierCopy.taxesInCents = recipeModifierTax;

            newSelectedFnb2[selectedFnbitemIndex2].Modifiers[
              modifiersLength2 - 1
            ].push(modifierCopy);
          }

          newSelectedFnb2[selectedFnbitemIndex2]['modifiersPriceInCents'] +=
            recipeModifierPriceWithTax;
          newSelectedFnb2[selectedFnbitemIndex2]['modifiersTaxesInCents'] +=
            recipeModifierTax;

          // newSelectedFnb2[selectedFnbitemIndex2]['PriceInCents'] += selectedModifier.PriceInCents;
          // newSelectedFnb2[selectedFnbitemIndex2]['TaxInCents'] += selectedModifier.TaxInCents;

          modifier_price.priceInCents += recipeModifierPriceWithTax;
          modifier_price.taxesInCents += recipeModifierTax;
        } else if (type === 'sub') {
          if (selectedModifierIndex2 > -1) {
            if (selectedModifier.Quantity != 0) {
              selectedModifier.Quantity--;
              newSelectedFnb2[selectedFnbitemIndex2].Modifiers[
                modifiersLength2 - 1
              ][selectedModifierIndex2].Quantity--;

              newSelectedFnb2[selectedFnbitemIndex2].Modifiers[
                modifiersLength2 - 1
              ][selectedModifierIndex2].priceInCents -= recipeModifierPriceWithTax;
              newSelectedFnb2[selectedFnbitemIndex2].Modifiers[
                modifiersLength2 - 1
              ][selectedModifierIndex2].taxesInCents -= recipeModifierTax;
              // newSelectedFnb2[selectedFnbitemIndex2]['PriceInCents'] -= selectedModifier.PriceInCents;
              // newSelectedFnb2[selectedFnbitemIndex2]['TaxInCents'] -= selectedModifier.TaxInCents;

              newSelectedFnb2[selectedFnbitemIndex2]['modifiersPriceInCents'] -=
                recipeModifierPriceWithTax;
              newSelectedFnb2[selectedFnbitemIndex2]['modifiersTaxesInCents'] -=
                recipeModifierTax;

              modifier_price.priceInCents -= recipeModifierPriceWithTax;
              modifier_price.taxesInCents -= recipeModifierTax;

              if (
                newSelectedFnb2[selectedFnbitemIndex2].Modifiers[
                  modifiersLength2 - 1
                ][selectedModifierIndex2].Quantity == 0
              ) {
                newSelectedFnb2[selectedFnbitemIndex2].Modifiers[
                  modifiersLength2 - 1
                ].splice(selectedModifierIndex2, 1);
              }
            }
          }
        }
      }

      return {
        ...state,
        selected_fnb_items: newSelectedFnb2,
        modifier_price: modifier_price,
        recipe_items: new_recipe_items,
      };
    case CLEAR_FNB_STATE:
    case CLEAR_FOOD_AND_BEVERAGES_STATE:
      console.log('FnbReducer: CLEAR_FOOD_AND_BEVERAGES_STATE');
      return {
        ...state,
        selected_fnb_items: [],
        fnb_price: {
          priceInCents: 0,
          taxesInCents: 0,
        },
        show_modifiers_popup: false,
        selected_modifiers: [],
        selected_fnb_item_id: null,
        modifier_price: {
          priceInCents: 0,
          taxesInCents: 0,
        },
        recipe_items: [],
        show_recipe_items_popup: false,
      };
    case FNB_RESERVATION_DONE:
      console.log('FnbReducer: FNB_RESERVATION_DONE');
      return {
        ...state,
        fnbReservationDone: true,
      };
    case SET_RECIPE_ITEMS:
      console.log('FnbReducer: SET_RECIPE_ITEMS');
      const recipe_items = action.payload;
      let concession_id = action.payload[0].concession_id;
      let selected_fnb_item_2 = state.selected_fnb_items.find(
        (item) => item.Id === concession_id,
      );
      let modifierLength = selected_fnb_item_2.Modifiers.length;
      let modifiers = selected_fnb_item_2.Modifiers[modifierLength - 1];

      recipe_items.forEach((recipe) => {
        recipe.ModifierGroups.forEach((modifier_group) => {
          modifier_group.Modifiers.forEach((modifier) => {
            modifier.Quantity = 0;
            modifier.priceInCents = 0;
            modifier.taxesInCents = 0;
            modifiers.forEach((modifier2) => {
              if (modifier2.Id === modifier.Id) {
                modifier.Quantity = modifier2.Quantity;
              }
            });
          });
        });
      });

      return {
        ...state,
        recipe_items: recipe_items,
        show_recipe_items_popup: true,
      };
    case CLOSE_RECIPE_MODIFIER_POPUP:
      console.log('FnbReducer: CLOSE_RECIPE_MODIFIER_POPUP');
      return {
        ...state,
        show_recipe_items_popup: false,
      };
    default:
      return state;
  }
};

export default fnbReducer;
